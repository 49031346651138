import React from 'react';


const More = () => {
    return (
        <div className="container py-5">
            <div className="row">

                {/*Start Udawalawa*/}
                <div className="col-lg-6 mb-4">
                    <div className="card">

                        <img src="img/Udwalawa.jpg" className="card-img-top" alt="Udawalawe National Park" style={{ height: '400px', objectFit: 'cover' }}/>
                        <div className="card-body" style={{maxHeight: '500px', overflowY: 'auto'}}>
                            <h2 className="card-title mb-3" style={{color: "#45a049"}}>Udawalawe National Park</h2>
                            <p className="card-text">
                                It is one of the best national parks in the country, home to enormous squirrels,
                                sambars, spotted deer, wild buffalo, and elephant populations. In fact, Udawalawe
                                frequently outperforms several of the most well-known national parks in East Africa for
                                elephant viewing. The park, which is centred on the 308.2-square-kilometer Uda Walawe
                                Reservoir, is sparsely vegetated, yet it has a stark beauty, and it is simple to see
                                wildlife because there isn't much of it. The main draw of Uda Walawe is its 600
                                elephants, who live in groups of up to 50 across the park.
                            </p>
                            <p>A large portion of the
                                park is surrounded by an elephant-proof barrier, which is meant to keep cattle out and
                                elephants in from regions with denser populations. Along unfenced boundaries, elephants
                                can and do migrate into and out of the park. The hours of 6.30 am to 10 am and 4 pm to
                                6.30 pm are the greatest times to see herds. The park is home to many crocodiles, sloth
                                bears, mongooses, jackals, water monitor lizards, and occasionally leopards in addition
                                to its well-known herds of elephants. There are 210 kinds of birds and 30 different
                                types of snakes; between November and April, northern migrants join the local
                                population.</p>
                            <h4>Nearby Places & Activities </h4>
                            <p className="card-text">
                                The abundant animals and breathtaking scenery of Udawalawe National Park, which is
                                tucked away in the centre of Sri Lanka, entice travellers. Experience thrilling wildlife
                                excursions where you can see beautiful deer, herds of elephants, and a variety of bird
                                species in their natural habitat. Photographers will enjoy taking pictures of the park's
                                varied wildlife and plants against expansive vistas. Take a guided walking tour of the
                                park's authorised paths to get a closer look at its thriving ecosystems. The Elephant
                                Transit Home is a must-see location where orphaned elephant calves receive care and
                                preparation for life in the wild.</p>
                            <p>Adjacent to the Udawalawe Dam, which displays
                                remarkable engineering in a picturesque setting, is the peaceful Udawalawe Reservoir,
                                which provides peaceful vistas and fantastic birdwatching chances. A trip through Sri
                                Lanka's natural treasures is guaranteed at Udawalawe National Park, whether you choose
                                to sleep beneath the stars or have a quiet picnic.</p>

                        <div className="clearfix">
                            <figure className="figure float-end me-3 mb-3 udawalawaPic01D" >
                                <img
                                    src="img/safari-Camp.jpg"
                                    className="figure-img img-fluid rounded"
                                    alt="Elephants in Udawalawe"
                                />
                                <figcaption className="figure-caption text-center">Tented Safari Camp-Udawalawe In Sri Lanka
                                    </figcaption>
                                </figure>
                                <h4>Nearby accommodations and Hotels </h4>
                                <p className="card-text">
                                    The park's periphery offers a wide selection of lodging options. However, rates are
                                    high; be prepared to pay more than you would in the highlands or along the shore.Day
                                    trips to the park are available from several south-coast resorts, Ella, Ratnapura,
                                    and Tissa. But if you take a trip from these locations, you'll be going to the park
                                    during the hottest part of the day, when all the animals are sleeping. It's
                                    recommended that you stay here for at least one night in order to take advantage of
                                    the early morning and evening safaris.
                                </p>

                            </div>

                            <h4 className="card-text">
                                Only 1 Dwarf Elephant in Asia is in Udawalawe ?
                            </h4>
                            <figure className="figure float-start me-3 mb-3 udawalawaPic01D" >
                                <img
                                    src="img/Kuruu.jpg"
                                    className="figure-img img-fluid rounded"
                                    alt="Elephants in Udawalawe"
                                />
                                <figcaption className="figure-caption text-center">Dwarf Elephant (Kuru Aliya)
                                </figcaption>
                            </figure>
                            <p className="card-text">
                                Among the different types of elephants in the world, African elephants are divided into
                                sub-species such as Asian elephants and have different heights. But this is about the
                                only one dwarf elephant in Asia. The dwarf elephant is in the Udawalawe National Park.
                            </p>
                            <p>The back legs are small compared to the front legs of this elephant. The head of this
                                elephant is very big compared to the body. Due to genetic mutations, dwarf elephants are
                                born and dwarf elephants also walk with some difficulty. But this dwarf elephant, which
                                was found in Udawalawa National Park in 2013, has not been seen in the park since 2020.
                                Many local and foreign tourists who have visited Udawalawa these days have had the
                                extremely rare opportunity to see this Karu elephant after a long time.</p>
                            <p className="card-text">
                                The park has an annual rainfall of 1,500 millimetres (59 in), most of which falls during
                                the
                                months of October to January and March to May. The average annual temperature is about
                                27–28 °C (81–82 °F),
                                while relative humidity varies from 70% to 83%. Well-drained reddish-brown soil is the
                                predominant soil type,
                                with poorly drained low humic grey soils found in the valley bottoms. Mainly alluvial
                                soils form
                                the beds of water courses
                            </p>
                            <h4 className="card-text">
                                Mammals
                            </h4>
                            <div className="clearfix">
                                <figure className="figure float-start me-3 mb-3 udawalawaPic01D" >
                                    <img
                                        src="img/UdawalaweAnimalNew.png"
                                        className="figure-img img-fluid rounded"
                                        alt="Elephants in Udawalawe"
                                    />
                                    <figcaption className="figure-caption text-center">Elephants in Udawalawe
                                    </figcaption>
                                </figure>
                                <p>
                                    Udawalawe is an important habitat for Sri Lankan elephants, which are relatively
                                    hard to
                                    see in its open habitats. Many elephants are attracted to the park because of the
                                    Udawalawe
                                    reservoir, with a herd of about 250 believed to be permanently resident. The
                                    Udawalawe
                                    Elephant Transit Home was established in 1995 for the purpose of looking after
                                    abandoned
                                    elephant calves within the park. A total of nine calves, on two occasions in 1998
                                    and 2000,
                                    with another eight calves in 2002, were released in the park when old enough to fend
                                    for themselves.
                                </p>
                            </div>
                            <p>
                                The rusty-spotted cat, fishing cat and Sri Lankan leopard are members of the family
                                Felidae present in Udawalawe.
                                The Sri Lankan sloth bear is seldom seen because of its rarity. Sri Lankan sambar deer,
                                Sri Lankan axis deer,
                                Indian muntjac, Sri Lankan spotted chevrotain, wild boar and water buffalo are among
                                other mammal species. Golden jackal,
                                Asian palm civet, toque macaque, tufted grey langur and Indian hare also inhabit the
                                park.
                            </p>
                            <p>
                                A study conducted in 1989
                                found that considerable numbers of golden palm civets inhabit the forests of Udawalawe.
                                Five species of mice also
                                have been recorded from the park. The endemic Ceylon spiny mouse, known from Yala
                                National Park, was recorded in Udawalawe
                                in 1989. Indian bush rat and three species of mongoose are also recorded in the national
                                park.
                            </p>
                            {/*Add more*/}
                        </div>
                    </div>
                </div>
                {/*End Udawalawa*/}

                {/*Start Yala*/}
                <div className="col-lg-6 mb-4">
                    <div className="card">
                        <img src="img/Yala.jpg" className="card-img-top" alt="Udawalawe National Park"
                             style={{height: '400px', objectFit: 'cover'}}/>
                        <div className="card-body" style={{maxHeight: '500px', overflowY: 'auto'}}>
                            <h2 className="card-title mb-3" style={{color: "#45a049"}}>Yala National Park</h2>
                            <div className="clearfix">
                                <figure className="figure float-start me-3 mb-3 udawalawaPic01D" >
                                    <img
                                        src="img/Yala2.jpg"
                                        className="figure-img img-fluid rounded"
                                        alt="Elephants in Udawalawe"
                                    />
                                    <figcaption className="figure-caption text-center">Tigers in tree
                                    </figcaption>
                                </figure>
                                <p className="card-text">
                                    One of Sri Lanka's must-see locations is Yala National Park, also called Ruhuna
                                    National Park. This outstanding game reserve, which is the most well-known in Sri
                                    Lanka, is situated in the southeast of the country for good reason. Yala is divided
                                    into five blocks and occupies an area of 1,260 square kilometres. <p>It is believed
                                    to have the highest density of leopards per square kilometre in the world. The most
                                    popular area of the reserve is Block I, which also offers the best opportunities for
                                    a leopard safari. Although leopards are normally difficult to spot, sightings in
                                    Yala are very good; you may see them lounging in the sun on rocky outcrops, in
                                    trees, across paths, and next to waterholes. Sloth bears, Asian elephants, deer,
                                    monkeys, buffalo, and several bird species coexist with leopards. Yala provides the
                                    finest chance to observe leopards in Sri Lanka if that's your top priority.</p>
                                </p>
                            </div>

                            <h4>History Of Yala</h4>
                            <p className="card-text">
                                Yala is one of the earliest national parks in Sri Lanka. It was first recognised as a
                                wildlife sanctuary in 1900, but it wasn't until 1938 that it was made into a national
                                park. The Buddhist temple Sithulpawa, located in the centre of Yala, was formerly a
                                monastery and is said to have been there for 2,000 years. The monastery is believed to
                                have supported 12,000 people at one point in time, and pilgrims from all around Sri
                                Lanka still come to see it as a significant temple. Nearby are two other important
                                cultural sites: Kataragama and Magul Maha Vihara.
                            </p>

                            <h4>Nearly Places and Activities </h4>
                            <p className="card-text">
                                When visiting Sri Lanka, it is imperative to have Yala on one's itinerary as a
                                significant location. Yala is a friendly tourist destination with many of sights to see,
                                situated 195 kilometres from Colombo and 138 km from Kandy. Some of these locations are
                                the Ruhunu Maha Kataragama Devalya, Kataragama Kiri Vehera, Situlpawwa, Magul
                                MahaViharaya, Lunugamvehera National Park, and Yala National Park. The city proudly
                                displays the variety of its natural wildlife while also honouring the nation's
                                long-standing Buddhist heritage. Yala also offers a plethora of thrilling activities,
                                such diving, cycling around lakes, safaris, and sightseeing.
                            </p>

                            <h4>Nearby Hotel Accommodations </h4>
                            <p>Numerous choices are available to suit different tastes and price ranges for people
                                looking for lodging close to Yala National Park. The close proximity to the park's edge,
                                cosy accommodations with views of the surrounding landscape, and convenient access to
                                safari trips are what make Cinnamon Wild Yala stand out. Jetwing Yala, a hotel with a
                                reputation for being environmentally conscious, offers opulence in the middle of the
                                wilderness. Its chic rooms offer expansive views of the surroundings. Chena Huts by Uga
                                Escapes provides opulent "huts" hidden away in the bush that blend contemporary
                                conveniences with a genuine safari atmosphere for a more private experience. .</p>
                            <p>In the meantime, Kithala Resort prioritises sustainability and offers environmentally
                                friendly hotel choices near the park that are perfect for those who love the outdoors.
                                Finally, the Wild Coast Tented Lodge, which is ideally located close to the entrance of
                                Yala National Park, provides a singular experience with its tented lodgings that combine
                                luxury with wilderness. With these choices, travellers can be guaranteed to fully
                                experience Yala's natural beauty and fauna while yet having cosy and enjoyable
                                vacations</p>
                            <h4>Flora</h4>
                            <p className="card-text">
                                Yala National Park has a variety of ecosystems including moist monsoon forests, dry
                                monsoon forests,
                                semi deciduous forests, thorn forests, grasslands, marshes, marine wetlands,
                                and sandy beaches. The area under forest cover mainly consists of Block I and
                                rangelands of open parkland (pelessa grasslands) including some extensive grasslands.
                                The forest area is restricted to around the Menik River while rangelands are found
                                towards the sea side
                            </p>
                            <p className="card-text">
                                Located in the southern part of Sri Lanka, Udawalawe covers an area of approximately
                                30,821 hectares and was established in 1972 to provide sanctuary for wild animals
                                displaced by the construction of the Udawalawe Reservoir on the Walawe River, as well as
                                to protect the catchment of the reservoir.
                            </p>
                            <p className="card-text">
                                The park is home to many species of birds, reptiles, and mammals, making it a paradise
                                for wildlife enthusiasts and photographers alike.
                            </p>
                            <h4>Birds</h4>
                            <div className="clearfix">
                                <figure className="figure float-end me-3 mb-3 udawalawaPic01D" >
                                    <img
                                        src="img/Yala4.jpg"
                                        className="figure-img img-fluid rounded"
                                        alt="Elephants in Udawalawe"

                                    />
                                    <figcaption className="figure-caption text-center">Peacock
                                    </figcaption>
                                </figure>
                                <p>
                                    Yala is one of the 70 Important Bird Areas (IBAs) in Sri Lanka. Of 215 bird species
                                    of the park,
                                    seven are endemic to Sri Lanka. They are Sri Lanka grey hornbill, Sri Lanka
                                    junglefowl, Sri Lanka
                                    wood pigeon, crimson-fronted barbet, black-capped bulbul, blue-tailed bee-eater and
                                    brown-capped babbler.
                                    The number of waterbirds inhabiting wetlands of Yala is 90 and half of them are
                                    migrants.
                                    Waterfowl (lesser whistling duck, garganey), cormorants (little cormorant, Indian
                                    cormorant),
                                    large waterbirds (grey heron, black-headed ibis, Eurasian spoonbill, Asian openbill,
                                    painted stork),
                                    medium-sized waders Tringa spp., and small waders Charadrius spp.are among the most
                                    common waterbirds
                                </p>
                                <p>
                                    Black-necked stork and lesser adjutant are many of the rare birds that can be seen
                                    in the park.
                                    The migrant great white pelican and resident spot-billed pelican are also have been
                                    recorded.
                                    Other waterbirds attracted to the Yala lagoons include lesser flamingo, pelicans,
                                    and rare species such as purple heron, night herons, egrets, purple swamphen, and
                                    Oriental darter.
                                    Thousands of waterfowls migrate to the lagoons of Yala during the northeast monsoon.
                                </p>

                            </div>
                            {/*Add more*/}
                        </div>
                    </div>
                </div>

                {/*End Yala*/}
            </div>
        </div>


    );
};

export default More;
