import React from "react";

export const Service = () => {

    return(

        <>

           {/* Page Header Start*/}
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-service" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h1 className="display-4 text-white mb-3 animated slideInDown">Services</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Services</li>
                        </ol>
                    </nav>
                </div>
            </div>
             {/*Page Header End*/}

             {/*Service Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="col-lg-6">
                            {/*<p><span className="text-primary me-2">#</span>Our Services</p>*/}
                            <h1 className="display-5 mb-0"> Services in <span className="text-primary">Vibes of Safari</span></h1>
                        </div>
                        {/*<div className="col-lg-6">
                            <div className="bg-primary h-100 d-flex align-items-center py-4 px-4 px-sm-5">
                                <i className="fa fa-3x fa-mobile-alt text-white"></i>
                                <div className="ms-4">
                                    <p className="text-white mb-0">Call for more info</p>
                                    <h2 className="text-white mb-0">+012 345 6789</h2>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                    <div className="row gy-5 gx-4">
                        <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                            <img className="img-fluid mb-3" src="img/icon/icon-2.png" alt="Icon"/>
                            <h5 className="mb-3">Car Parking</h5>
                            <span>We provide enough space to park your vehicles.</span>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                            <img className="img-fluid mb-3" src="img/icon/icon-4.png" alt="Icon"/>
                            <h5 className="mb-3">Experienced Guide Service </h5>
                            <span>Our guides and drivers are full of knowledge and they are bound to give you that knowledge.</span>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                            <img className="img-fluid mb-3" src="img/icon/icon-5.png" alt="Icon"/>
                            <h5 className="mb-3">Food & Beverages</h5>
                            <span>We provide breakfast, lunch along with water bottles and soft drinks depending on the packages. </span>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                            <img className="img-fluid mb-3" style={{height:'60px', width:'60px'}} src="img/icon/drop.png" alt="Icon"/>
                            <h5 className="mb-3">Pick Up and Drop</h5>
                            <span>We offer hotel pickup and drop to nearby hotels in the Park area.</span>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                            <img className="img-fluid mb-3" src="img/icon/icon-9.png" alt="Icon"/>
                            <h5 className="mb-3">Rest House</h5>
                            <span>Comfortable hotels & resorts are available nearby. We help you with finding a good place.</span>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                            <img className="img-fluid mb-3" style={{height:'90px', width:'90px'}} src="img/icon/jeep.png" alt="Icon"/>
                            <h5 className="mb-3">Comfortable Safari Jeeps</h5>
                            <span>We provide comfortable 4x4 Safari jeeps. </span>
                        </div>
                        {/*<div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                            <img className="img-fluid mb-3" src="img/icon/icon-3.png" alt="Icon"/>
                            <h5 className="mb-3">Breakfast & Water Bottles</h5>
                            <span>Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</span>
                        </div>*/}
                        <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                            <img className="img-fluid mb-3" style={{height:'90px', width:'90px'}} src="img/icon/location.png" alt="Icon"/>
                            <h5 className="mb-3">Transportation  </h5>
                            <span>We can arrange you transportation facilities from Mirissa, Ella, Arugambay and suburbs depending on your need on a extra charge. </span>
                        </div>
                       {/* <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                            <img className="img-fluid mb-3" src="img/icon/icon-5.png" alt="Icon"/>
                            <h5 className="mb-3">Lunch & Soft Drinks
                            </h5>

                            <span>Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</span>

                        </div>*/}
                    </div>
                </div>
            </div>
        </>
    )
}
