import React from "react";

export const Visiting = () => {

    return (
        <div>
            {/*Page Header Start*/}
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-VisitingHourBg" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h1 className="display-4 text-white mb-3 animated slideInDown">Visiting Hours</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Visiting Hours</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Page Header End */}


            {/*Visiting Hours Start*/}
            <div className="container-xxl bg-primary visiting-hours my-5 py-5 wow fadeInUp header_bg-visitingHour"
                 data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-md-6 wow fadeIn" data-wow-delay="0.3s">
                            <h1 className="display-6 text-white mb-5">Visiting Hours & Available Options </h1>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <span> </span>
                                    {/* <span>9:00AM - 6:00PM</span> */}
                                </li>
                                <li className="list-group-item">
                                    <span>Safari’s are open everyday from </span>
                                    <span>6:00 AM to 4:30 PM</span>
                                </li>
                                <li className="list-group-item">
                                    <span style={{color: 'greenyellow'}}>We recommend that early morning and the evening time will be the ideal time to have this experience. </span>

                                </li>

                                {/* <li class="list-group-item">
                                    <span>Saturday</span>
                                    <span>9:00AM - 6:00PM</span>
                                </li>
                                <li class="list-group-item">
                                    <span>Sunday</span>
                                    <span>Closed</span>
                                </li> */}
                            </ul>
                        </div>
                        <div className="col-md-6 text-light wow fadeIn" data-wow-delay="0.5s">

                            <table className="table">
                                <tbody>
                                <tr>
                                    <td>Regular Safari</td>
                                    <td>3/4 hr</td>
                                </tr>
                                <tr>
                                    <td>Half Day Safari</td>
                                    <td>6hr</td>
                                </tr>
                                <tr>
                                    <td>Full Day Safari</td>
                                    <td>12hr</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/*Visiting Hours End */}
        </div>
    )
}
