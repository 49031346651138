import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';

export const Packages = () => {

    const navigate = useNavigate();

    const handleBookNowClick = (packageName) => {
        setSelectedPackage(packageName);
        navigate('/contact', { state: { selectedPackage: packageName } }); // Pass selected package name as state
        setTimeout(() => {
            scroller.scrollTo('cform', {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        }, 100); // Adjust the timeout as needed
    };

    const [showHide, setShowHide] = useState(false);

    const handleToggleHideShow = () => {
        setShowHide(!showHide);
    };

    const [showYalaNormal, setShowYalaNormal] = useState(false);
    const [showUdawalaNormal, setShowUdawalaNormal] = useState(false);
    const [showYalaHalf, setShowYalaHalf] = useState(false);
    const [showUdawalaweHalf, setShowUdawalawelHalf] = useState(false);
    const [showYalaFull, setShowYalaFull] = useState(false);
    const [showUdawalaweFull, setUdawalawefull] = useState(false);


    const handleUdawalawaShow = (packageType) => {
        setSelectedPackage(packageType);
        setShowYalaNormal(true);
    };

    const handleYalaShow = (packageType) => {
        setSelectedPackage(packageType);
        setShowUdawalaNormal(true);
    };

    const handleYalaHalfShow = (packageType) => {
        setSelectedPackage(packageType);
        setShowYalaHalf(true);
    };

    const handleUdawalaweHalfShow = (packageType) => {
        setSelectedPackage(packageType);
        setShowUdawalawelHalf(true);
    };

    const handleYalaFullShow = (packageType) => {
        setSelectedPackage(packageType);
        setShowYalaFull(true);
    };

    const handleUdawalaweFullShow = (packageType) => {
        setSelectedPackage(packageType);
        setUdawalawefull(true);
    };

    const handleUdawalawaClose = () => setShowYalaNormal(false);
    const handleYalaHalf = () => setShowYalaHalf(false);
    const handleUdawalaweHalf = () => setShowUdawalawelHalf(false);
    const handleYalaFull = () => setShowYalaFull(false);
    const handleUsawalweFull = () => setUdawalawefull(false);
    const handleYalaClose = () => setShowUdawalaNormal(false);


    const [show, setShow] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);

    const handleClose = () => setShow(false);

    const handleShow = (pkg) => {
        setSelectedPackage(pkg);
        setShow(true);
    };

    const [visible, setVisible] = useState(false)

    const [isReadMoreVisible1, setReadMoreVisible1] = useState(false);
    const [isReadMoreVisible2, setReadMoreVisible2] = useState(false);
    const [isReadMoreVisible3, setReadMoreVisible3] = useState(false);

    const handleReadMoreToggleNormal = () => setReadMoreVisible1(!isReadMoreVisible1);
    const handleReadMoreToggleHalf = () => setReadMoreVisible2(!isReadMoreVisible2);
    const handleReadMoreToggleFull = () => setReadMoreVisible3(!isReadMoreVisible3);


    /*-----------------------------------------*/
    const [isReadMoreVisible4, setIsReadMoreVisible4] = useState(false);
    const [isReadMoreVisible5, setIsReadMoreVisible5] = useState(false);
    const [isReadMoreVisible6, setIsReadMoreVisible6] = useState(false);

    const handleReadMoreToggleNormal4 = () => {
        setIsReadMoreVisible4(!isReadMoreVisible4);
    };
    const handleReadMoreToggleHalf5 = () => {
        setIsReadMoreVisible5(!isReadMoreVisible5);
    };
    const handleReadMoreToggleFull6 = () => {
        setIsReadMoreVisible6(!isReadMoreVisible6);
    };


    const [showModal, setShowModal] = useState(false);
    const [selectedPark, setSelectedPark] = useState('udawalawe');
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);


    return (

        <>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="py-5">
                        <div className="row g-5 mb-5 align-items-end wow fadeInUp" data-wow-delay="0.1s">
                            <div className="col-lg-6">
                                <h1 className="display-5 mb-0"> <span
                                    className="text-primary">Udawalawa</span> Packages <span
                                        className="text-primary"></span></h1>
                            </div>
                            {/*<div className="col-lg-6 text-lg-end">
                                <a className="btn btn-primary py-3 px-5" href="#">Special Pricing</a>
                            </div>*/}
                        </div>
                        <div className="row g-4" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {/*-------------------------*/}
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s"
                                style={{ display: 'flex' }}>
                                <div
                                    className="membership-item position-relative d-flex flex-column"
                                    style={{ flexGrow: 1, backgroundColor: 'white', border: '2px solid black' }}
                                >
                                    <h4 className="text-dark mb-3">Normal safari (3 hr)</h4>
                                    {/*<p className="text-dark mb-3" style={{color: 'yellow'}}>
                                        You can see a sizable portion of the national park in these three hours. It will
                                        allow you to witness a variety of species. For photographers, a three-hour
                                        safari might not be sufficient.If you are lucky enough to observe wildlife early
                                        in the tour, you will have the
                                        {isReadMoreVisible1 && (
                                            <span>
                                <br/>opportunity to get out of the jeep. If not, the
                                        driver will search for wild creatures by traveling to other locations. you're short on time; if not, opt for a half-day safari and save a little more money.
                                <ul>
                                    <li>Free hotel pickup and drop (Udawalawa area)</li>
                                    <li>Feel free to contact VS directly for questions or bookings; please avoid contacting us through others.</li>
                                    <li>You can't feed animals in the park. It's not allowed.</li>
                                    <li>Not wheelchair accessible</li>
                                </ul>
                            </span>
                                        )}
                                    </p>*/}
                                    <p className="text-dark mb-3" style={{ color: 'yellow', textAlign: 'start' }}>
                                        This is the ideal safari with a limited time. In three hours, you can cover a
                                        good area of the
                                        park. You can select the morning or evening time to explore wildlife & capture
                                        moments!
                                    </p>
                                    {/*<button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleNormal}
                                        style={{textDecoration: 'underline', cursor: 'pointer'}}
                                    >
                                        {isReadMoreVisible1 ? 'Read Less' : 'Read More'}
                                    </button>*/}

                                    {/* <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user"
                                            style={{ color: 'black', paddingRight: '23px' }}></i> person - <span
                                                style={{ fontSize: '20px', fontWeight: 'bold' }}>24 $</span>
                                    </p>
                                    <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black', marginRight: '5px' }}></i>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> persons - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>27 $ <h5>(Price varies by group size - maximum 6 pax per jeep)</h5></span>
                                    </p> */}
                                    <p style={{ fontSize: '20px', fontWeight: 'bold', color: 'black', display: 'flex', justifyContent: 'center' }}>Package Inludes:</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>4 x 4
                                        Luxury safari jeep</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Free hotel
                                        pick up and drop (Udawalawa area)</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Well
                                        experienced safari jeep driver</p>

                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Water
                                        bottles</p>
                                    <p className='text-dark'><i className="fa fa-times text-danger me-3"></i>Not
                                        included national park entrance tickets</p>



                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>
                                        {isReadMoreVisible1 && (
                                            <span>
                                                <ul>
                                                    <u><h6 style={{ textAlign: 'center' }}>Additional Info</h6></u>
                                                    <li>Feel free to contact Us directly for questions or bookings, please avoid contacting us through others.</li>
                                                    <li>You can't feed animals in the park. It's not allowed.</li>
                                                    <li>Not wheelchair accessible</li>
                                                </ul>
                                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleNormal}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {isReadMoreVisible1 ? 'Read Less' : 'Read More'}
                                    </button>

                                    <button className="btn btn-outline-light px-4 mt-auto text-dark"
                                        style={{ border: '2px solid #4CAF50', backgroundColor: '#4CAF50' }}
                                        onClick={() => handleBookNowClick('Udawalawa - Normal safari (3 hr)')}>
                                        Book Now
                                    </button>
                                </div>
                            </div>
                            {/*------------------*/}
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s"
                                style={{ display: 'flex' }}>
                                <div
                                    className="membership-item position-relative d-flex flex-column"
                                    style={{ flexGrow: 1, backgroundColor: 'white', border: '2px solid black' }}
                                >
                                    <h4 className="text-dark mb-3">Half Day Safari (6hr)</h4>
                                    <p className="text-dark mb-3" style={{ color: 'yellow', textAlign: 'start' }}>
                                        This 6 hours safari will allow you to cover a considerable area of the park. You
                                        can choose morning or evening time. You can get down from the jeep and enjoy
                                        moments. This is an ideal safari for photographers.
                                    </p>
                                    {/*<p className="text-dark mb-3" style={{color: 'yellow'}}>
                                        For those who wish to learn more about the park and its species, including
                                        researchers, photographers, adventurers, and wildlife aficionados, this is the
                                        greatest safari. You will be able to explore many biodiversity zones and
                                        asizable
                                        {isReadMoreVisible2 && (
                                            <span>
                                <br/>
                                         portion of the park.Lunch is available in the park. This Safari is highly recommended, particularly for photographers.
                                <ul>
                                    <li>Breakfast</li>
                                    <li>Water</li>
                                    <li>You can't feed animals in the park. It's not allowed.</li>
                                    <li>Not wheelchair accessible</li>
                                </ul>
                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleHalf}
                                        style={{textDecoration: 'underline', cursor: 'pointer'}}
                                    >
                                        {isReadMoreVisible2 ? 'Read Less' : 'Read More'}
                                    </button>*/}
                                    
                                    {/* <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user"
                                            style={{ color: 'black', paddingRight: '23px' }}></i> person - <span
                                                style={{ fontSize: '20px', fontWeight: 'bold' }}>45 $</span>
                                    </p>
                                    <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black', marginRight: '5px' }}></i>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>48 $ <h5>(Price varies by group size - maximum 6 pax per jeep)</h5></span>
                                    </p> */}
                                    <p style={{ fontSize: '20px', fontWeight: 'bold', color: 'black', display: 'flex', justifyContent: 'center' }}>Package Inludes:</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>4 x 4
                                        Luxury safari jeep</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Free hotel
                                        pick up and drop (Udawalawa area)</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Well
                                        experienced safari jeep driver</p>
                                    <p className="text-dark "><i className="fa fa-check text-primary me-3"></i>Breakfast
                                    </p>
                                    <p className="text-dark "><i className="fa fa-check text-primary me-3"></i>Water
                                        bottles
                                    </p>
                                    <p className='text-dark'><i className="fa fa-times text-danger me-3"></i>Not
                                        included national park entrance tickets</p>



                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>
                                        {isReadMoreVisible2 && (
                                            <span>
                                                <ul>
                                                    <u><h6 style={{ textAlign: 'center' }}>Additional Info</h6></u>
                                                    <li>Feel free to contact Us directly for questions or bookings, please avoid contacting us through others.</li>
                                                    <li>You can't feed animals in the park. It's not allowed.</li>
                                                    <li>Not wheelchair accessible</li>
                                                </ul>
                                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleHalf}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {isReadMoreVisible2 ? 'Read Less' : 'Read More'}
                                    </button>
                                    <button className="btn btn-outline-light px-4 mt-auto text-dark"
                                        style={{ border: '2px solid #4CAF50', backgroundColor: '#4CAF50' }}
                                        onClick={() => handleBookNowClick('Udawalawa - Half Day Safari (6hr)')}>
                                        Book Now
                                    </button>
                                </div>
                            </div>

                            {/*-------------------*/}
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s"
                                style={{ display: 'flex' }}>
                                <div
                                    className="membership-item position-relative d-flex flex-column"
                                    style={{ flexGrow: 1, backgroundColor: 'white', border: '2px solid black' }}
                                >
                                    <h4 className="text-dark mb-3">Full Day Safari (12hr)</h4>
                                    <p className="text-dark mb-3" style={{ color: 'yellow', textAlign: 'start' }}>
                                        You can cover a huge area of the park and this is recommended for photographers
                                        researchers and wildlife enthusiasts. You can go to different biodiversity areas
                                        within this time & you can also have the lunch in the park.
                                    </p>
                                    {/*<p className="text-dark mb-3" style={{color: 'yellow'}}>
                                        You can see a sizable portion of the national park in these three hours. It will
                                        allow you to witness a variety of species. For photographers, a three-hour
                                        safari might not be sufficient.If you are lucky enough to observe wildlife early
                                        in the tour,you will have the opportunity to get out of
                                        {isReadMoreVisible3 && (
                                            <span>
                                <br/>   the jeep. If not, the driver will search for wild creatures by traveling to other locations. Select this safari if you're short on time; if not, opt for a half-day safari and save a little more money.
                                <ul>
                                    <li>Free hotel pickup and drop (Udawalawa area)</li>
                                    <li>Feel free to contact VS directly for questions or bookings; please avoid contacting us through others.</li>
                                    <li>You can't feed animals in the park. It's not allowed.</li>
                                    <li>Not wheelchair accessible</li>
                                </ul>
                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleFull}
                                        style={{textDecoration: 'underline', cursor: 'pointer'}}
                                    >
                                        {isReadMoreVisible3 ? 'Read Less' : 'Read More'}
                                    </button>*/}

                                    {/* <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user"
                                            style={{ color: 'black', paddingRight: '23px' }}></i> person - <span
                                                style={{ fontSize: '20px', fontWeight: 'bold' }}>93 $</span></p>
                                    <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black', marginRight: '5px' }}></i>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>97 $ <h5>(Price varies by group size - maximum 6 pax per jeep)</h5></span>
                                    </p> */}
                                    <p style={{ fontSize: '20px', fontWeight: 'bold', color: 'black', display: 'flex', justifyContent: 'center' }}>Package Inludes:</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>4 x 4
                                        Luxury safari jeep</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Free hotel
                                        pick up and drop (Udawalawa area)</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Well
                                        experienced safari jeep driver</p>

                                    <p className="text-dark"><i className="fa fa-check text-primary me-3"></i>Breakfast
                                        & Lunch
                                    </p>

                                    <p className="text-dark"><i className="fa fa-check text-primary me-3"></i>Water
                                        bottles</p>
                                    <p className="text-dark"><i className="fa fa-check text-primary me-3"></i> soft
                                        Drinks</p>
                                    <p className='text-dark'><i className="fa fa-times text-danger me-3"></i>Not
                                        included national park entrance tickets</p>


                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>
                                        {isReadMoreVisible3 && (
                                            <span>
                                                <ul>
                                                    <u><h6 style={{ textAlign: 'center' }}>Additional Info</h6></u>
                                                    <li>Feel free to contact Us directly for questions or bookings, please avoid contacting us through others.</li>
                                                    <li>You can't feed animals in the park. It's not allowed.</li>
                                                    <li>Not wheelchair accessible</li>
                                                </ul>
                                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleFull}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {isReadMoreVisible3 ? 'Read Less' : 'Read More'}
                                    </button>

                                    <button className="btn btn-outline-light px-4 mt-auto text-dark"
                                        style={{ border: '2px solid #4CAF50', backgroundColor: '#4CAF50' }}
                                        onClick={() => handleBookNowClick('Udawalawa - Full Day Safari (12hr)')}>
                                        Book Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Yala Packages */}
                    <div className="py-5">
                        <div className="row g-5 mb-5 align-items-end wow fadeInUp" data-wow-delay="0.1s">
                            <div className="col-lg-6">
                                <h1 className="display-5 mb-0"> <span
                                    className="text-primary">Yala</span> Packages <span
                                        className="text-primary"></span></h1>
                            </div>
                            {/*<div className="col-lg-6 text-lg-end">
                                <a className="btn btn-primary py-3 px-5" href="#">Special Pricing</a>
                            </div>*/}
                            <ul>
                                <li>
                                    <p style={{ color: 'black', fontWeight: 'bold', fontSize: '17px' }}>No entry or
                                        movement in the park is
                                        allowed from 12noon
                                        to 2p.m</p>
                                </li>
                            </ul>
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6 wow fadeInUp" style={{ display: 'flex', flexWrap: 'wrap' }}
                                data-wow-delay="0.1s">
                                {/* Normal Safari Yala */}
                                <div
                                    className="membership-item position-relative d-flex flex-column "

                                    style={{ height: 'auto', backgroundColor: 'white', border: '2px solid black' }}
                                >

                                    {/*<h1 className="display-1">01</h1>*/}
                                    <h4 className="text-dark mb-3">Normal safari (4 hr)</h4>
                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>
                                        This is the perfect safari for those with limited time. In four hours, you can
                                        explore a significant part of the park. Our guide/driver will explain all things
                                        about wild animals and the national park. Choose a morning or evening safari to
                                        observe wildlife and capture memorable moments!
                                    </p>
                                    {/*<p className="text-dark mb-3" style={{color: 'yellow'}}>
                                        You can see a sizable portion of the national park in these three hours. It will allow you to witness a variety of species. For photographers, a three-hour safari might not be sufficient.
                                        Yala normal safari is a 4 hour Safari tour in Yala National Park. You can
                                        witness the
                                        wonders of wild life and capture those moments within in this time. And, you can
                                        choose
                                        the morning safari or evening safari depending on your preference.
                                        {isReadMoreVisible4 && (
                                            <span>
                                        <br/>And our driver will give you the all wild life information you need for what you witness and not. Our driver will however show you a leopard!

                                        Join with us to have the best Yala Wildlife experience!
                                        <ul>
                                            <li>Water bottel</li>
                                            <li>Free hotel pickup & drop(Yala area)</li>
                                            <li>Experienced Driver & Guide</li>
                                            <li>4x4 Comfortable Safari Jeep</li>
                                        </ul>
                                    </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleNormal4}
                                        style={{textDecoration: 'underline', cursor: 'pointer'}}
                                    >
                                        {isReadMoreVisible4 ? 'Read Less' : 'Read More'}
                                    </button>*/}

                                    {/* <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black', paddingRight: '23px' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>53 $</span> </p>
                                    <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black', marginRight: '5px' }}></i>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>58 $ <h5>(Price varies by group size - maximum 6 pax per jeep)</h5></span>
                                    </p> */}
                                    <p style={{ fontSize: '20px', fontWeight: 'bold', color: 'black', display: 'flex', justifyContent: 'center' }}>Package Inludes:</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>4 x 4
                                        Luxury
                                        safari jeep
                                    </p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Free hotel
                                        pick up
                                        and drop(Yala area)
                                    </p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Well
                                        experienced
                                        safari jeep
                                        driver</p>

                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Water
                                        bottles</p>
                                    <p className='text-dark'><i className="fa fa-times text-danger me-3"></i>
                                        Not included national park entrance tickets
                                    </p>

                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>

                                        {isReadMoreVisible4 && (
                                            <span>
                                                <ul>
                                                    <u><h6 style={{ textAlign: 'center' }}>Additional Info</h6></u>
                                                    <li>Feel free to contact Us directly for questions or bookings, please avoid contacting us through others.</li>
                                                    <li>You can't feed animals in the park. It's not allowed.</li>
                                                    <li>Not wheelchair accessible</li>
                                                </ul>
                                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleNormal4}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {isReadMoreVisible4 ? 'Read Less' : 'Read More'}
                                    </button>

                                    <button className="btn btn-outline-light px-4 mt-auto text-dark"
                                        style={{ border: '2px solid #4CAF50', backgroundColor: '#4CAF50' }}
                                        onClick={() => handleBookNowClick('Yala - Normal safari (4 hr)')}>
                                        Book Now
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                {/* Half Day Safari */}
                                <div
                                    className="membership-item position-relative d-flex flex-column "
                                    style={{ height: 'auto', backgroundColor: 'white', border: '2px solid black' }}>
                                    {/*<h1 className="display-1">02</h1>*/}
                                    <h4 className="text-dark mb-3">Half Day Safari (6hr)</h4>
                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>
                                        This six-hour safari lets you explore a substantial portion of the park. You can
                                        choose between morning or evening safari or tour. Our guide/driver will explain
                                        all things about wild animals and the national park. You’ll have opportunities
                                        to get down from the jeep and enjoy your surroundings. It's an ideal option for
                                        photographers.
                                    </p>
                                    {/*<p className="text-dark mb-3" style={{color: 'yellow'}}>
                                        You can observe a wide variety of wild creatures and cover a sizable portion of the park in a half-day safari ride. It takes five to six hours for this trip.
                                        Yala Half Day Safari will be for 6 hours. You can have a vivid experience of
                                        wildlife
                                        and capture those moments in a time duration of 6 hours. And, you can choose
                                        morning or
                                        evening safari depending on your preference.Our guide/driver will do their best
                                        to
                                        explain
                                        {isReadMoreVisible5 && (
                                            <span>
                                        <br/>This makes it better for taking pictures. In certain areas, you can exit the jeep.
                                                everything to you and he will make sure you witness a leopard!
                                        Join                                                with us to have the most adventurous wildlife tour in Yala!
                                        <ul>
                                            <li>Water bottel</li>
                                            <li>Free hotel pickup & drop(Yala area)</li>
                                            <li>Experienced Driver & Guide</li>
                                            <li>4x4 Comfortable Safari Jeep</li>
                                        </ul>
                                    </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleHalf5}
                                        style={{textDecoration: 'underline', cursor: 'pointer'}}
                                    >
                                        {isReadMoreVisible5 ? 'Read Less' : 'Read More'}
                                    </button>*/}

                                    {/* <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user"
                                            style={{ color: 'black', paddingRight: '23px' }}></i> person - <span
                                                style={{ fontSize: '20px', fontWeight: 'bold' }}>73 $</span></p>
                                    <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black', marginRight: '5px' }}></i>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>78 $ <h5>(Price varies by group size - maximum 6 pax per jeep)</h5></span>
                                    </p>
                                     */}
                                    <p style={{ fontSize: '20px', fontWeight: 'bold', color: 'black', display: 'flex', justifyContent: 'center' }}>Package Inludes:</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>4 x 4
                                        Luxury
                                        safari jeep
                                    </p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Free hotel
                                        pick up
                                        and drop(Yala area)
                                    </p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Well
                                        experienced
                                        safari jeep
                                        driver</p>

                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Water
                                        bottles</p>

                                    <p className='text-dark'><i className="fa fa-times text-danger me-3"></i>
                                        Not included national park entrance tickets
                                    </p>
                                    <p className='text-dark'><i className="fa fa-times text-danger me-3"></i>Breakfast
                                    </p>
                                    {/* <p className='text-white'><i></i></p>
                                    <p className='text-white'><i></i></p>
                                    <p className='text-white'><i></i></p> */}

                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>

                                        {isReadMoreVisible5 && (
                                            <span>
                                                <ul>
                                                    <u><h6 style={{ textAlign: 'center' }}>Additional Info</h6></u>
                                                    <li>Feel free to contact Us directly for questions or bookings, please avoid contacting us through others.</li>
                                                    <li>You can't feed animals in the park. It's not allowed.</li>
                                                    <li>Not wheelchair accessible</li>
                                                </ul>
                                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleHalf5}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {isReadMoreVisible5 ? 'Read Less' : 'Read More'}
                                    </button>

                                    <button className="btn btn-outline-light px-4 mt-auto text-dark"
                                        style={{ border: '2px solid #4CAF50', backgroundColor: '#4CAF50' }}
                                        onClick={() => handleBookNowClick('Yala - Half Day Safari (6hr)')}>
                                        Book Now
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                {/* Full Day Safari */}
                                <div
                                    className="membership-item position-relative d-flex flex-column "
                                    style={{ height: 'auto', backgroundColor: 'white', border: '2px solid black' }}
                                >
                                    {/*<h1 className="display-1">03</h1>*/}
                                    <h4 className="text-dark mb-3">Full Day Safari (12hr)</h4>

                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>
                                        This twelve-hour safari allows you to explore a vast area of the park, making it
                                        perfect for photographers, researchers, and wildlife enthusiasts. Our
                                        guide/driver will explain all things about wild animals and the national park.
                                        You'll have the chance to visit various biodiversity zones and enjoy lunch
                                        within the park.
                                    </p>
                                    {/*<p className="text-dark mb-3" style={{color: 'yellow'}}>
                                        For those who wish to learn more about the park and its species, including
                                        researchers, photographers, adventurers, and wildlife aficionados, this is the
                                        greatest safari.You will be able to explore many biodiversity zones and a
                                        sizable
                                        portion of the park.
                                        {isReadMoreVisible6 && (
                                            <span>
                                        <br/>Lunch is available in the park. This Safari is highly recommended, particularly for photographers.
                                        <ul>
                                            <li>Lunch</li>
                                            <li>Water bottles & soft Drinks</li>
                                            <li>4x4 Comfortable Safari Jeep</li>
                                            <li>Experienced Driver & Guide</li>
                                            <li>Water bottles</li>
                                        </ul>
                                    </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleFull6}
                                        style={{textDecoration: 'underline', cursor: 'pointer'}}
                                    >
                                        {isReadMoreVisible6 ? 'Read Less' : 'Read More'}
                                    </button>*/}

                                    {/* <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user"
                                            style={{ color: 'black', paddingRight: '18px' }}></i> person - <span
                                                style={{ fontSize: '20px', fontWeight: 'bold' }}>123 $</span></p>
                                    <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>128 $ <h5>(Price varies by group size - maximum 6 pax per jeep)</h5></span>
                                    </p>
                                     */}
                                     
                                    <p style={{ fontSize: '20px', fontWeight: 'bold', color: 'black', display: 'flex', justifyContent: 'center' }}>Package Inludes:</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>4 x 4
                                        Luxury
                                        safari jeep
                                    </p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Free hotel
                                        pick up
                                        and drop(Yala area)
                                    </p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Well
                                        experienced
                                        safari jeep
                                        driver</p>

                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Lunch</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>Water
                                        bottles</p>
                                    <p className='text-dark'><i className="fa fa-check text-primary me-3"></i>
                                        soft Drinks</p>
                                    <p className='text-dark'><i className="fa fa-times text-danger me-3"></i>
                                        Not included national park entrance tickets
                                    </p>
                                    <p className='text-dark'><i className="fa fa-times text-danger me-3"></i>Breakfast
                                    </p>
                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>
                                        {isReadMoreVisible6 && (
                                            <span>
                                                <ul>
                                                    <u><h6 style={{ textAlign: 'center' }}>Additional Info</h6></u>
                                                    <li>Feel free to contact Us directly for questions or bookings, please avoid contacting us through others.</li>
                                                    <li>You can't feed animals in the park. It's not allowed.</li>
                                                    <li>Not wheelchair accessible</li>
                                                </ul>
                                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleFull6}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {isReadMoreVisible6 ? 'Read Less' : 'Read More'}
                                    </button>

                                    <button className="btn btn-outline-light px-4 mt-auto text-dark"
                                        style={{ border: '2px solid #4CAF50', backgroundColor: '#4CAF50' }}
                                        onClick={() => handleBookNowClick('Yala - Full Day Safari (12hr)')}>
                                        Book Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}