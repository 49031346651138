import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const NavBar = () => {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 300);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const openWhatsApp = () => {
        // Replace '1234567890' with your WhatsApp number, including country code
        const whatsappNumber = '+94779371477';
        const message = encodeURIComponent('Hello, I would like to know more about...');
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${message}`;

        window.open(whatsappUrl, '_blank' , 'width=600,height=600');
    };

    const handleClick = () => {
        /* scrollToTop();*/
        openWhatsApp();
    };

    return (


        /*<nav
            className={`navbar navbar-expand-lg bg-white navbar-light sticky-top py-lg-0 px-4 px-lg-5 ${isSticky ? 'py-lg-0 px-4 px-lg-5 wow fadeIn' : ''}`}
            data-wow-delay="0.1s">
            <a href="/" className="navbar-brand p-0"
               style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                <img className=" img-nav me-3 " src="img/VOS.png" alt="Icon"
                     /!*style={{maxHeight: '140px', position: 'absolute', zIndex: 5, marginTop: '40px', paddingLeft: 0}}*!//>
                <h1 className="title23 m-0 "
                    /!*style={{color: 'black', paddingLeft: '130px', fontSize: '30px', paddingTop: '20px'}}*!/>VIBES OF
                    SAFARI</h1>
            </a>
            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse py-4 py-lg-0" id="navbarCollapse ">
                <div className="navbar-nav ms-auto ">
                    <a href="/" className="nav-item nav-link">Home</a>
                    <a href="about" className="nav-item nav-link">About</a>
                    <a href="service" className="nav-item nav-link">Services</a>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                        <div className="dropdown-menu rounded-0 rounded-bottom m-0">
                            <a href="animal" className="dropdown-item">Our Animals</a>
                            <a href="membership" className="dropdown-item">Membership</a>
                            <a href="visiting" className="dropdown-item">Visiting Hours</a>
                            <a href="testimonial" className="dropdown-item">Blog</a>
                        </div>
                    </div>
                    <a href="contact" className="nav-item nav-link">Contact</a>
                </div>
                <a href="" className="btn btn-primary">Call Us<i className="fa fa-arrow-right ms-3"></i></a>
            </div>
        </nav>*/

        <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top py-lg-0 px-4 px-lg-5 wow fadeIn"
             data-wow-delay="0.1s">
            <a href="/" className="navbar-brand p-0"
               style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                <img className=" img-nav me-3 defaultImage" src="img/VOS.png" alt="Icon"/>
                <h1 className="title23 m-0 defaultTitle ">VIBES OF
                    SAFARI</h1>
            </a>
            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse py-4 py-lg-0" id="navbarCollapse">
                <div className="navbar-nav ms-auto">
                    <a href="/" className="nav-item nav-link">Home</a>
                    <a href="about" className="nav-item nav-link">About</a>
                    <a href="service" className="nav-item nav-link">Services</a>
                    <a href="packages" className="nav-item nav-link">Packages</a>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                        <div className="dropdown-menu rounded-0 rounded-bottom m-0">
                           {/* <a href="animal" className="dropdown-item">Our Animals</a>*/}
                            {/*<a href="membership" className="dropdown-item">Membership</a>*/}
                            <a href="visiting" className="dropdown-item">Visiting Hours</a>
                            <a href="testimonial" className="dropdown-item">Blog</a>
                        </div>
                    </div>
                    <a href="contact" className="nav-item nav-link">Contact</a>
                </div>
                <button onClick={handleClick} className="btn btn-primary">
                    Call Us <i className="fa fa-arrow-right ms-3"></i>
                </button>
            </div>
        </nav>
    )
}