import { BrowserRouter, Route, Routes } from "react-router-dom";
import { About } from "./Pages/About";
import { Topper } from "./Component/Topper";
import { NavBar } from "./Component/NavBar";
import { Footer } from "./Component/Footer";
import { Animals } from "./Pages/Animals";
import { Visiting } from "./Pages/Visiting";
import { Membership } from "./Pages/Membership";
import { Notfound } from "./Pages/404";
import { Service } from './Pages/Service';
import { Contact } from './Pages/Contact';
import { Testimonial } from './Pages/Testimonial';

import {Home} from "./Pages/Home";

import More from "./Pages/More";
import {Packages} from "./Pages/Packages";



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Topper />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About />} />
          <Route path='/animal' element={<Animals />} />
          <Route path='/visiting' element={<Visiting />} />
          <Route path='/member' element={<Membership />} />
          <Route path="/service" element={<Service />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/more" element={<More />} />
          <Route path="/packages" element={<Packages />} />

          <Route path='*' element={<Notfound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>

  );
}

export default App;
