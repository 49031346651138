import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import React, { useEffect, useState } from 'react';

export const About = () => {

    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        const fetchReviews = async () => {
            const placeId = 'ChIJh0K8AzQH5DoRm5bX0J6rttg';  // Replace with your actual place ID
            const apiKey = 'AIzaSyCnBf9N86JS3r5fnh5W6RJ7zSh_ssN93G4';  // Replace with your Google Places API key
            const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
            const targetUrl = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${apiKey}`;

            try {
                const response = await fetch(proxyUrl + targetUrl);
                const data = await response.json();
                console.log('dddd', data)
                if (data.result && data.result.reviews) {
                    setReviews(data.result.reviews);
                }
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        };

        fetchReviews();
    }, []);

    return (
        <div>

            {/* Spinner Start */}

            {/*<div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
          <div className="spinner-border text-primary" style={{width: '3rem', height: '3rem'}} role="status">
              <span className="sr-only">Loading...</span>
          </div>
      </div>*/}

            {/* Spinner End */}


            {/* Page Header Start */}

            <div
                className="container-fluid header-bg-about py-5 mb-5 wow fadeIn"
                data-wow-delay="0.1s"
                style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                <div className="container py-5">
                    <h1 className="display-4 text-white mb-3 animated slideInDown">About Us</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">About Us</li>
                        </ol>
                    </nav>
                </div>
            </div>

            {/* Page Header End */}

            {/* About Start */}

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <h1 className="display-5 mb-4">Why you should  <span
                                className="text-primary">choose us?</span></h1>

                            <p className="mb-4" style={{ fontSize: '18px', textAlign: 'center' }}>
                                Since 2017, for 8 years, we are a team of experienced national park
                                Safari tour providers who are dedicated to give you memories for a lifetime.
                                We care about each and every detail of the Safari tour giving you the most knowledgeable
                                experience of Udawalawe and Yala Safari. Our drivers and guides have the exact knowledge of
                                each and every animal in the parks. Further, we are a renowned and highly appreciated Safari
                                providers among customers.
                                Finally, our main goal will be to plant the real experience of wildlife in your mind!
                            </p>




                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="img-border">
                                <img className="img-fluid" src="img/aboutUs.jpg" alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* About End */}


            {/*  Facts Start */}
            <div className="container-xxl bg-primary facts my-5 py-5 wow fadeInUp header_bg-AboutUdaYala" data-wow-delay="0.1s">
                <div className="container py-5">
                    {/*----------------Udawalawa National---------------------*/}

                    <div className="udawalawe-section" style={{ minHeight: '100px' }}>
                        <h2 style={{
                            color: 'white',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '40px'
                        }}>Udawalawe National Park</h2>
                        <div className="row g-4 mt-5">
                            <div className="col-md-6 col-lg-3 text-center wow fadeIn flex justify-content-start"
                                data-wow-delay="0.1s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-paw fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Total Animals</p>
                                <p className="text-white mb-2" data-toggle="counter-up">Udawalawe park include 94
                                    plants, 21 fishes, 12 amphibians, 33 reptiles, 184 birds (33 of which are migratory),
                                    and 43 mammals</p>

                            </div>

                            <div className="col-md-6 col-lg-3 text-center wow fadeIn flex justify-content-start"
                                data-wow-delay="0.5s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-certificate fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Area</p>
                                <p className="text-white mb-2" data-toggle="counter-up"> 119 sq moles</p>

                            </div>
                            <div className="col-md-6 col-lg-3 text-center wow fadeIn flex justify-content-start"
                                data-wow-delay="0.7s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-shield-alt fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Climate</p>
                                <p className="text-white mb-2" data-toggle="counter-up">Dry most of the year</p>

                            </div>
                            <div className="col-md-6 col-lg-3 text-center wow fadeIn flex justify-content-start"
                                data-wow-delay="0.3s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-users fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Nearby Places </p>
                                <p className="text-white mb-2" data-toggle="counter-up">Elephant Orphanage, Sankalpa
                                    Viharaya, Maduwanwela Walawwa,
                                </p>

                            </div>
                        </div>
                    </div>

                    {/*----------------Yal National---------------------*/}

                    <div className="yala-section mt-5" style={{ minHeight: '100px' }}>
                        <h2 style={{ color: 'white', textAlign: 'center', fontWeight: 'bold', fontSize: '40px' }}>Yala
                            National Park</h2>
                        <div className="row g-4 mt-5">
                            <div className="col-md-6 col-lg-3 text-center wow fadeIn justify-content-start"
                                data-wow-delay="0.1s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-paw fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Total Animals</p>
                                <p className="text-white mb-2" data-toggle="counter-up">Yala is home to 44 varieties of
                                    mammals and 215 bird species.</p>

                            </div>

                            <div className="col-md-6 col-lg-3 text-center wow fadeIn justify-content-start"
                                data-wow-delay="0.5s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-certificate fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Area</p>
                                <p className="text-white mb-2" data-toggle="counter-up">378 sq miles</p>

                            </div>
                            <div className="col-md-6 col-lg-3 text-center wow fadeIn justify-content-start"
                                data-wow-delay="0.7s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-shield-alt fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Climate</p>
                                <p className="text-white mb-2" data-toggle="counter-up">Dry most of the year</p>

                            </div>
                            <div className="col-md-6 col-lg-3 text-center wow fadeIn justify-content-start"
                                data-wow-delay="0.3s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-users fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Nearby Places </p>
                                <p className="text-white mb-2" data-toggle="counter-up">Buduruwagala, Okanda Devalaya,
                                    Kebiliththa, Sithulpawwa, Magul Maha Viharaya
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="reviews-section">
                <h2 style={{ color: 'black', textAlign: 'center', fontWeight: 'bold', fontSize: '40px' }}>Google Reviews</h2>
                <div className="row g-4 mt-5">
                    {reviews.map((review, index) => (
                        <div key={index} className="col-12 col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.1s" style={{ minHeight: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <p className="text-black mb-2" style={{ fontWeight: 'bold' }}>{review.author_name}</p>
                            <p className="text-black review-text" style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 10, WebkitBoxOrient: 'vertical' }}>{review.text}</p>
                            <p className="text-black mb-0">Rating: {review.rating}⭐</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}