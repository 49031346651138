export const Topper = () => {

    const openWhatsApp = () => {
        // Replace '1234567890' with your WhatsApp number, including country code
        const whatsappNumber = '+94779371477';
        const message = encodeURIComponent('Hello, I would like to know more about...');
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${message}`;

        window.open(whatsappUrl, '_blank' , 'width=600,height=600');
    };
    const handleClick = () => {
        /* scrollToTop();*/
        openWhatsApp();
    };
    return (
        <div>
            {/*Topbar Start*/}
            <div className="container-fluid bg-light p-0 wow fadeIn" data-wow-delay="0.1s">
                <div className="row gx-0 d-none d-lg-flex">
                    <div className="col-lg-7 px-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <small className="fa fa-map-marker-alt text-primary me-2"></small>
                            <small>Family Quarters, T.20.C, Udawalawa 70190</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center py-3">
                            <small className="far fa-clock text-primary me-2"></small>
                            <small>Mon - Fri : 08.00 AM - 04.30 PM</small>
                        </div>
                    </div>
                    <div className="col-lg-5 px-5 text-end">
                        <button
                            onClick={handleClick} className="border-0">
                            <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                                <small className="fa fa-phone-alt text-primary me-2"></small>
                                <small>+94 779 371 477</small>
                            </div>
                        </button>

                        <div className="h-100 d-inline-flex align-items-center">
                            <a className="btn btn-sm-square bg-white text-primary me-1"
                               href="https://www.facebook.com/share/L1wz2Tj2FosqWUhe/?mibextid=qi2Omg" target="_blank"><i
                                className="fab fa-facebook-f"></i></a>
                            {/*<a className="btn btn-sm-square bg-white text-primary me-1" href=""><i
                                className="fab fa-twitter"></i></a>
                            <a className="btn btn-sm-square bg-white text-primary me-1" href=""><i
                                className="fab fa-linkedin-in"></i></a>*/}
                            <a className="btn btn-sm-square bg-white text-primary me-0" href="https://www.instagram.com/vibesofsafari?igsh=YnNzY3R4Y29zMDV1" target="_blank"><i
                                className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Topbar End*/}
        </div>
    )
}
