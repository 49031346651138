export const Membership = () => {

    return (
        <div>

            {/*Page Header Start */}
            <div class="container-fluid header-bg py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div class="container py-5">
                    <h1 class="display-4 text-white mb-3 animated slideInDown">Membership</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol class="breadcrumb mb-0">
                            <li class="breadcrumb-item"><a class="text-white" href="/">Home</a></li>
                            <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                            <li class="breadcrumb-item text-primary active" aria-current="page">Membership</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/*Page Header End*/}


            {/*Membership Start */}
            <div class="container-xxl py-5">
                <div class="container">
                    <div class="row g-5 mb-5 align-items-end wow fadeInUp" data-wow-delay="0.1s">
                        <div class="col-lg-6">
                            <p><span class="text-primary me-2">#</span>Membership</p>
                            <h1 class="display-5 mb-0">You Can Be A Proud Member Of <span class="text-primary">Zoofari</span></h1>
                        </div>
                        <div class="col-lg-6 text-lg-end">
                            <a class="btn btn-primary py-3 px-5" href="">Special Pricing</a>
                        </div>
                    </div>
                    <div class="row g-4">
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="membership-item position-relative">
                                <img class="img-fluid" src="img/animal-lg-1.jpg" alt=""></img>
                                <h1 class="display-1">01</h1>
                                <h4 class="text-white mb-3">Popular</h4>
                                <h3 class="text-primary mb-4">$99.00</h3>
                                <p><i class="fa fa-check text-primary me-3"></i>10% discount</p>
                                <p><i class="fa fa-check text-primary me-3"></i>2 adult and 2 child</p>
                                <p><i class="fa fa-check text-primary me-3"></i>Free animal exhibition</p>
                                <a class="btn btn-outline-light px-4 mt-3" href="">Get Started</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div class="membership-item position-relative">
                                <img class="img-fluid" src="img/animal-lg-2.jpg" alt=""></img>
                                <h1 class="display-1">02</h1>
                                <h4 class="text-white mb-3">Standard</h4>
                                <h3 class="text-primary mb-4">$149.00</h3>
                                <p><i class="fa fa-check text-primary me-3"></i>15% discount</p>
                                <p><i class="fa fa-check text-primary me-3"></i>4 adult and 4 child</p>
                                <p><i class="fa fa-check text-primary me-3"></i>Free animal exhibition</p>
                                <a class="btn btn-outline-light px-4 mt-3" href="">Get Started</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="membership-item position-relative">
                                <img class="img-fluid" src="img/animal-lg-3.jpg" alt=""></img>
                                <h1 class="display-1">03</h1>
                                <h4 class="text-white mb-3">Premium</h4>
                                <h3 class="text-primary mb-4">$199.00</h3>
                                <p><i class="fa fa-check text-primary me-3"></i>20% discount</p>
                                <p><i class="fa fa-check text-primary me-3"></i>6 adult and 6 child</p>
                                <p><i class="fa fa-check text-primary me-3"></i>Free animal exhibition</p>
                                <a class="btn btn-outline-light px-4 mt-3" href="">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Membership End */}
        </div>
    )
}
