export const Animals = () => {

    return (
        <div>
            {/* Page Header Start */}
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-OurAnnimals" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h1 className="display-4 text-white mb-3 animated slideInDown">Our Animals</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Our Animals</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/*Page Header End */}

            {/*Animal Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 mb-5 align-items-end wow fadeInUp" data-wow-delay="0.1s">
                        <div className="col-lg-6">
                            <h1 className="display-5 mb-0">Let`s See Our <span
                                className="text-primary">Yala</span> & <span
                                className="text-primary">Udawalawe</span> Awesome Animals</h1>
                        </div>
                        <div className="col-lg-6 text-lg-end">
                            <a className="btn btn-primary py-3 px-5" href="">Explore More Animals</a>
                        </div>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="row g-4">
                                <div className="col-12">
                                    <a className="animal-item" href="img/ele3.jpg" data-lightbox="animal">
                                        <div className="position-relative fixed-size">
                                            <img className="img-fluid fixed-size" src="img/ele3.jpg" alt="Elephant"/>
                                            <div className="animal-text p-4">
                                                <p className="text-white small text-uppercase mb-0">Animal</p>
                                                <h5 className="text-white mb-0">Elephant</h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-12">
                                    <a className="animal-item" href="img/udawalawaAnimal.png" data-lightbox="animal">
                                        <div className="position-relative fixed-size">
                                            <img className="img-fluid fixed-size" src="img/udawalawaAnimal.png"
                                                 alt="Deers"/>
                                            <div className="animal-text p-4">
                                                <p className="text-white small text-uppercase mb-0">Animal</p>
                                                <h5 className="text-white mb-0">Deers</h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-12">
                                    <a className="animal-item" href="img/yalaAnimal4.png" data-lightbox="animal">
                                        <div className="position-relative fixed-size">
                                            <img className="img-fluid fixed-size" src="img/yalaAnimal4.png"
                                                 alt="Monkey"/>
                                            <div className="animal-text p-4">
                                                <p className="text-white small text-uppercase mb-0">Animal</p>
                                                <h5 className="text-white mb-0">Leopards</h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="row g-4">
                                <div className="col-12">
                                    <a className="animal-item" href="img/udawalawaAnimal5.png" data-lightbox="animal">
                                        <div className="position-relative fixed-size">
                                            <img className="img-fluid fixed-size" src="img/udawalawaAnimal5.png"
                                                 alt="Peacock"/>
                                            <div className="animal-text p-4">
                                                <p className="text-white small text-uppercase mb-0">Animal</p>
                                                <h5 className="text-white mb-0">Peacock</h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-12">
                                    <a className="animal-item" href="img/udawalawaAnimal3.png" data-lightbox="animal">
                                        <div className="position-relative fixed-size">
                                            <img className="img-fluid fixed-size" src="img/udawalawaAnimal3.png"
                                                 alt="Dwarf Elephant"/>
                                            <div className="animal-text p-4">
                                                <p className="text-white small text-uppercase mb-0">Animal</p>
                                                <h5 className="text-white mb-0">Dwarf Elephant</h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-12">
                                    <a className="animal-item" href="img/udawalawaAnimal4.png" data-lightbox="animal">
                                        <div className="position-relative fixed-size">
                                            <img className="img-fluid fixed-size" src="img/udawalawaAnimal4.png"
                                                 alt="Dwarf Elephant"/>
                                            <div className="animal-text p-4">
                                                <p className="text-white small text-uppercase mb-0">Animal</p>
                                                <h5 className="text-white mb-0">Painted storks</h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="row g-4">
                                <div className="col-12">
                                    <a className="animal-item" href="img/yalaAnimal (5).png" data-lightbox="animal">
                                        <div className="position-relative fixed-size">
                                            <img className="img-fluid fixed-size" src="img/yalaAnimal (5).png"
                                                 alt="Blue-tailed bee-eater"/>
                                            <div className="animal-text p-4">
                                                <p className="text-white small text-uppercase mb-0">Animal</p>
                                                <h5 className="text-white mb-0">Blue-tailed bee-eater</h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-12">
                                    <a className="animal-item" href="img/yalaAnimal (10).png" data-lightbox="animal">
                                        <div className="position-relative fixed-size">
                                            <img className="img-fluid fixed-size" src="img/yalaAnimal (10).png"
                                                 alt="Crested Hawk Eagle"/>
                                            <div className="animal-text p-4">
                                                <p className="text-white small text-uppercase mb-0">Animal</p>
                                                <h5 className="text-white mb-0">Crested Hawk Eagle</h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-12">
                                    <a className="animal-item" href="img/yalaAnimal1.png" data-lightbox="animal">
                                        <div className="position-relative fixed-size">
                                            <img className="img-fluid fixed-size" src="img/yalaAnimal1.png"
                                                 alt="Crested Hawk Eagle"/>
                                            <div className="animal-text p-4">
                                                <p className="text-white small text-uppercase mb-0">Animal</p>
                                                <h5 className="text-white mb-0">Great hornbill</h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Animal End */}
        </div>
    )
}
