export const Testimonial = () => {

    return(

        <>
             {/*Page Header Start */}
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-TestimonialBg" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h1 className="display-4 text-white mb-3 animated slideInDown">Blog</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Blog</li>
                        </ol>
                    </nav>
                </div>
            </div>
          {/*  Page Header End */}


           {/*  Testimonial Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <h1 className="display-5 text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">Our Clients Say!</h1>
                    <div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
                        <div className="testimonial-item text-center">
                            <img className="img-fluid rounded-circle border border-2 p-2 mx-auto mb-4" src="img/testimonial-1.jpg" style={{width: '100px' , height: '100px'}}/>
                                <div className="testimonial-text rounded text-center p-4">
                                    <p>Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.</p>
                                    <h5 className="mb-1">Patient Name</h5>
                                    <span className="fst-italic">Profession</span>
                                </div>
                        </div>
                        <div className="testimonial-item text-center">
                            <img className="img-fluid rounded-circle border border-2 p-2 mx-auto mb-4" src="img/testimonial-2.jpg" style={{width: '100px' , height: '100px'}}/>
                                <div className="testimonial-text rounded text-center p-4">
                                    <p>Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.</p>
                                    <h5 className="mb-1">Patient Name</h5>
                                    <span className="fst-italic">Profession</span>
                                </div>
                        </div>
                        <div className="testimonial-item text-center">
                            <img className="img-fluid rounded-circle border border-2 p-2 mx-auto mb-4" src="img/testimonial-3.jpg" style={{width: '100px' , height: '100px'}}/>
                                <div className="testimonial-text rounded text-center p-4">
                                    <p>Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.</p>
                                    <h5 className="mb-1">Patient Name</h5>
                                    <span className="fst-italic">Profession</span>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
           {/* Testimonial End*/}
        </>
    )
}