import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
export const Footer = () => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsVisible(window.scrollY > 100);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const openWhatsApp = () => {
        // Replace '1234567890' with your WhatsApp number, including country code
        const whatsappNumber = '+94779371477';
        const message = encodeURIComponent('Hello, I would like to know more about...');
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${message}`;

        window.open(whatsappUrl, '_blank' , 'width=600,height=600');
    };

    const handleClick = () => {
       /* scrollToTop();*/
        openWhatsApp();
    };
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <div>
            {/*Footer Start */}
            <div className="container-fluid footer bg-dark text-light footer mt-5 pt-5 wow fadeIn header_bg-footer"
                 data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-light mb-4">Address</h5>
                            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Family Quarters, T.20.C, Udawalawa 70190
                            </p>
                            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+94 779 371 477</p>
                            <p className="mb-2"><i className="fa fa-envelope me-3"></i>vibesofsafari@gmail.com</p>
                            <div className="d-flex pt-2">
                               {/* <a className="btn btn-outline-light btn-social" href=""><i
                                    className="fab fa-twitter"></i></a>*/}
                                <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/share/L1wz2Tj2FosqWUhe/?mibextid=qi2Omg" target="_blank"><i
                                    className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-outline-light btn-social" href="https://www.instagram.com/vibesofsafari?igsh=YnNzY3R4Y29zMDV1" target="_blank"><i
                                    className="fab fa-instagram"></i></a>
                                {/*<a className="btn btn-outline-light btn-social" href=""><i
                                    className="fab fa-linkedin-in"></i></a>*/}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-light mb-4">Quick Links</h5>
                            <a className="btn btn-link" href="/about">About Us</a>
                            <a className="btn btn-link" href="/contact">Contact Us</a>
                            <a className="btn btn-link" href="/service">Our Services</a>
                            <a className="btn btn-link" href="">Terms & Condition</a>
                            <a className="btn btn-link" href="">Support</a>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-light mb-4">Popular Links</h5>
                            <a className="btn btn-link" href="/about">About Us</a>
                            <a className="btn btn-link" href="/contact">Contact Us</a>
                            <a className="btn btn-link" href="/service">Our Services</a>
                            <a className="btn btn-link" href="">Terms & Condition</a>
                            <a className="btn btn-link" href="">Support</a>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-light mb-4">Newsletter</h5>
                            <p>Plan your safari and send us your inquiry to us!</p>
                            <div className="position-relative mx-auto" style={{maxWidth: '400px'}}>
                                <input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="text"
                                       placeholder="Your email"></input>
                                <button type="button"
                                        className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a className="border-bottom" href="#">Vibes of Safari</a>, All Right Reserved.
                            </div>
                            <div className="col-md-6 text-center text-md-end">

                                Designed By <a className=" text-white" target="_blank" href="https://loopyholdings.com/"> Loopy Holdings </a> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Footer End */}

            {/*    Back to Top */}
            {isVisible && (
                <button className="btn btn-lg btn-primary btn-lg-square back-to-top rounded-button "
                        onClick={handleClick}>
                    <FontAwesomeIcon icon={faWhatsapp}/>
                </button>


            )}
        </div>
    )
}
