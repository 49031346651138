import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {noop} from "bootstrap/js/src/util";
import {Col, Container, Row} from "react-bootstrap";
import {Typography} from "@mui/material";


export default function ResponsiveDialogUdawalawa() {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" style={{color: 'white', borderColor: '#2EB872', backgroundColor:'#2EB872'}} onClick={handleClickOpen}>
                Things to do in Udawalawa
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className="hide-scrollbar"
            >

                <DialogTitle id="responsive-dialog-title" sx={{borderBottom: '2px solid black'}}>
                    <Typography variant='h5' style={{display:'flex',justifyContent:'center',textAlign:'center'}} fontWeight='600'> Things to do in Udawalawe & Places to stay near!</Typography>


                </DialogTitle>
                <DialogContent >
                    <DialogContentText>
                        <Container className="hide-scrollbar">
                        <Row className="my-4 row-sm">
                            <Col xs={12}>
                                <Typography variant='h6' sx={{ color: '#2EB872',display:'flex',justifyContent:'center' }}> Some nice things to do around Udawalawe!</Typography>
                            </Col>
                        </Row>
                            {/*/////////*/}
                        <Row className="my-4 row-sm">
                            <Col xs={12} sm={6} md={6}>
                                <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                     src='img/yalalallaalal.jpg' alt='Kiriwehera' />
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                                <Typography variant='body1' sx={{ color: '#000000', fontWeight: 'bold' }}>Udawalawe Reservoir</Typography>

                                <Typography variant='body2'>Udawalawe Reservoiris a reservoir situated on the border of Ratnapura and Monaragala Districts,
                                    Sri Lanka. Located within the Udawalawe National Park, the reservoir has been built by
                                    constructing a long earthen embankment across the Walawe Ganga River.</Typography>
                            </Col>
                        </Row>

                        <Row className="myGridContainer my-4" >
                            <Col xs={12} sm={6} md={6}>
                                <Typography variant='body1' sx={{ color: '#000000', fontWeight: 'bold' }}>Udawalawe Dam</Typography>

                                <Typography variant='body2'>The Udawalawe Dam is a large irrigation dam in Udawalawe, in the Southern Province of Sri Lanka. The dam consists of an embankment section and a gravity section, combining the total dam length to approximately 3.9 km.<br/>
                                    Location: B427, Udawalawe National Park Sri Lanka</Typography>
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                                <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                     src='img/Udawalawe Dam Sri Lanka 5.png' alt='Katharagama Dewalaya' />
                            </Col>
                        </Row>


                            <Row className="my-4 row-sm">
                                <Col xs={12} sm={6} md={6}>
                                    <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                         src='img/udawalawe1.jpg' alt='Kiriwehera' />
                                </Col>
                                <Col xs={12} sm={6} md={6}>
                                    <Typography variant='body1' sx={{ color: '#000000', fontWeight: 'bold' }}>Elephant Transit Home
                                    </Typography>

                                    <Typography variant='body2'>
                                        Located just close to the national park lies the elephant transit home where people can take a glimpse of the elephants taking a round around the grass.<br/>
                                        Public viewing of feeding is permitted daily at 10.30a.m., 2.30 p.m., and at 6 p.m.
<br/>
                                        Location: C.P.D De Silva Rd, B427, Udawalawa, Sri Lanka

                                    </Typography>
                                </Col>
                            </Row>

                            <Row className="myGridContainer my-4" >
                                <Col xs={12} sm={6} md={6}>
                                    <Typography variant='body1' sx={{ color: '#000000', fontWeight: 'bold' }}>Sankapala Raja Maha Viharaya
                                    </Typography>

                                    <Typography variant='body2'>
                                        Sankapala Rajamaha Viharaya stands majestically on a rocky mountain as ample evidence to prove that this temple has been blessed by the saintly monks who spent their time in peaceful meditation.<br/>

                                        Situated in the Ratnapura district belonging to the Atakalan Korale Thambagamu Pattu Pallebedda Village the Sankapala Rajamaha Viharaya lies on the outskirts of Ratnapura – Hambantota main road near the 24th milepost.
                                    </Typography>
                                </Col>
                                <Col xs={12} sm={6} md={6}>
                                    <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                         src='img/Sankapala1.png' alt='Sankapala1 Viharaya' />
                                </Col>
                            </Row>
                            <Row className="my-4 row-sm">
                                <Col xs={12} sm={6} md={6}>
                                    <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                         src='img/maduwanwela_walawwa0_g1.jpg' alt='Kiriwehera' />
                                </Col>
                                <Col xs={12} sm={6} md={6}>
                                    <Typography variant='body1' sx={{ color: '#000000', fontWeight: 'bold' }}>Maduwanwela Walawwa (Nobleman’s House)


                                    </Typography>

                                    <Typography variant='body2'>This is the reputed home of Maduwanwela Disawe (1877-1905), who was a regional ruler in the British era. Its initial construction is said to have taken place as early as 1700AC, when the country was under the rule of King Wimaladharmasuriya II.

                                    </Typography>
                                </Col>
                            </Row>

                            {/*////////*/}

                            <Row className="my-4 row-sm">
                            <Col xs={12}>
                                <Typography variant='h6' sx={{ color: '#2EB872',display:'flex',justifyContent:'center' }}>Some iconic places for you to stay near the Udawalawe !</Typography>
                            </Col>
                        </Row>

                        <Row className="my-4 row-sm">
                            <Col xs={12} sm={6} md={6}>
                                <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                     src='img/grandUdawalawe.jpg' alt='Yala Hotel 1' />
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                                <Typography variant='body1' sx={{ color: '#000000', fontWeight: 'bold' }}>grand udawalawe safari resort</Typography>
                                <Typography variant='body2'>
                                    The Grand Udawalawe Safari Resort, situated on 7 acres of land, is located 163 kilometers away from Colombo, and only 500 meters from the Ath Athuru Sevana (Elephant Orphanage), and is only a five minutes drive from the Uda Walawe National Park.
                                </Typography>
                            </Col>
                        </Row>

                        <Row className=" myGridContainer my-4">
                            <Col xs={12} sm={6} md={6}>
                                <Typography variant='body1' sx={{ color: '#000000', fontWeight: 'bold' }}>Elephant Trails</Typography>
                                <Typography variant='body2'>If you’re looking for a family-friendly hotel in Uda Walawe National Park, look no further than Elephant Trails.

                                    Rooms at Elephant Trails offer a flat screen TV and air conditioning providing exceptional comfort and convenience, and guests can go online with free internet access.
                                </Typography>
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                                <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                     src='img/elephant-trails.jpg' alt='Yala Near Hotel 4' />
                            </Col>
                        </Row>

                        <Row className="my-4 row-sm">
                            <Col xs={12} sm={6} md={6}>
                                <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                     src='img/waraka.jpg' alt='Yala Hotel 3' />
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                                <Typography variant='body1' sx={{ color: '#000000', fontWeight: 'bold' }}>Waraka Udawalawe</Typography>
                                <Typography variant='body2'>
                                    A serene, nature-friendly boutique hotel, ideal for families and travelers, nestled amidst the lush surroundings near Udawalawe National Park. Here, tranquility merges seamlessly with modern comforts, providing a revitalizing escape from urban life.
                                </Typography>
                            </Col>
                        </Row>
                    </Container>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}