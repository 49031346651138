import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useLocation } from 'react-router-dom';

export const Contact = () => {

    const location = useLocation();
    const selectedPackage = location.state ? location.state.selectedPackage : '';

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
        members:'',
        selectedPackage: selectedPackage,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_yxojlzi',       // Replace with your EmailJS service ID
            'template_80629cj',      // Replace with your EmailJS template ID
            formData,
            'Pg4rhGK8AtvmLQgdI'           // Replace with your EmailJS user ID
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert('Message sent successfully!');
        })
        .catch((err) => {
            console.error('FAILED...', err);
            alert('Failed to send message. Please try again later.');
        });

        setFormData({
            name: '',
            email: '',
            subject: '',
            message: '',
            members: '',
            selectedPackage: selectedPackage,
        });
    };

    return (
        <>
            {/* Page Header Start */}
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-contact" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h1 className="display-4 text-white mb-3 animated slideInDown">Contact Us</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Contact Us</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Page Header End */}

            {/* Contact Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-4 mb-5">
                        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="h-100 bg-light d-flex align-items-center p-5">
                                <div className="btn-lg-square bg-white flex-shrink-0">
                                    <i className="fa fa-map-marker-alt text-primary"></i>
                                </div>
                                <div className="ms-4">
                                    <p className="mb-2"><span className="text-primary me-2">#</span>Address</p>
                                    <h5 className="mb-0">Family Quarters, T.20.C, Udawalawa 70190</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="h-100 bg-light d-flex align-items-center p-5">
                                <div className="btn-lg-square bg-white flex-shrink-0">
                                    <i className="fa fa-phone-alt text-primary"></i>
                                </div>
                                <div className="ms-4">
                                    <p className="mb-2"><span className="text-primary me-2">#</span>Call Now</p>
                                    <h5 className="mb-0">+94 779 371 477</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100 bg-light d-flex align-items-center p-5">
                                <div className="btn-lg-square bg-white flex-shrink-0">
                                    <i className="fa fa-envelope-open text-primary"></i>
                                </div>
                                <div className="ms-4">
                                    <p className="mb-2"><span className="text-primary me-2">#</span>Mail Now</p>
                                    <h5 className="mb-0">vibesofsafari@gmail.com</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5" id="cform">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">



                            <h1 className="display-5 mb-4">Plan your safari and send us your inquiry to us!</h1>
                            {selectedPackage && (
                                <p className="mb-4 text-danger" style={{fontWeight:'bold'}}>Selected Package: {selectedPackage}</p>
                            )}
                            <form onSubmit={handleSubmit}>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control bg-light border-0"
                                                id="name"
                                                placeholder="Your Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="email"
                                                className="form-control bg-light border-0"
                                                id="email"
                                                placeholder="Your Email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control bg-light border-0"
                                                id="subject"
                                                placeholder="Subject"
                                                name="subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="subject">Subject</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input
                                                type="number"
                                                className="form-control bg-light border-0"
                                                id="members"
                                                placeholder="Number of persons"
                                                name="members"
                                                value={formData.members}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="members">Number of persons</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea
                                                className="form-control bg-light border-0"
                                                placeholder="Leave a message here"
                                                id="message"
                                                style={{height: '100px'}}
                                                name="message"
                                                value={formData.message}
                                                onChange={handleChange}
                                            ></textarea>
                                            <label htmlFor="message">Message</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary w-100 py-3" type="submit">
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100" style={{minHeight: '400px'}}>
                                <iframe className="rounded w-100 h-100"
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15859.224957224871!2d80.8189981!3d6.4189372!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae4073403bc4287%3A0xd8b6ab9ed0d7969b!2sVibes%20Of%20Safari!5e0!3m2!1sen!2slk!4v1719916393009!5m2!1sen!2slk"
                                        frameborder="0" allowfullscreen="" aria-hidden="false"
                                        tabindex="0"></iframe>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact End */}
        </>
    );
};
