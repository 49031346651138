
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { Modal, Button } from 'react-bootstrap';

import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Expnable, { Collapsible } from "../Component/ExpnableUdawalawe";
// import {CButton, CCard, CCardBody, CCollapse} from "@coreui/react";
import ResponsiveDialogUdawalawa from "../Component/ExpnableUdawalawe";
import ResponsiveDialogYala from "../Component/ExpnableYala"; // This only needs to be imported once in your app
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
export const Home = () => {

    const [showModal, setShowModal] = useState(false);
    const [selectedPark, setSelectedPark] = useState('udawalawe');
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const [showHide, setShowHide] = useState(false);

    const handleToggleHideShow = () => {
        setShowHide(!showHide);
    };

    const [showYalaNormal, setShowYalaNormal] = useState(false);
    const [showUdawalaNormal, setShowUdawalaNormal] = useState(false);
    const [showYalaHalf, setShowYalaHalf] = useState(false);
    const [showUdawalaweHalf, setShowUdawalawelHalf] = useState(false);
    const [showYalaFull, setShowYalaFull] = useState(false);
    const [showUdawalaweFull, setUdawalawefull] = useState(false);



    const handleUdawalawaShow = (packageType) => {
        setSelectedPackage(packageType);
        setShowYalaNormal(true);
    };

    const handleYalaShow = (packageType) => {
        setSelectedPackage(packageType);
        setShowUdawalaNormal(true);
    };

    const handleYalaHalfShow = (packageType) => {
        setSelectedPackage(packageType);
        setShowYalaHalf(true);
    };

    const handleUdawalaweHalfShow = (packageType) => {
        setSelectedPackage(packageType);
        setShowUdawalawelHalf(true);
    };

    const handleYalaFullShow = (packageType) => {
        setSelectedPackage(packageType);
        setShowYalaFull(true);
    };

    const handleUdawalaweFullShow = (packageType) => {
        setSelectedPackage(packageType);
        setUdawalawefull(true);
    };

    const handleUdawalawaClose = () => setShowYalaNormal(false);
    const handleYalaHalf = () => setShowYalaHalf(false);
    const handleUdawalaweHalf = () => setShowUdawalawelHalf(false);
    const handleYalaFull = () => setShowYalaFull(false);
    const handleUsawalweFull = () => setUdawalawefull(false);
    const handleYalaClose = () => setShowUdawalaNormal(false);
    const images = {
        udawalawe: [
            { src: 'img/UdawalaweAnimalNew.png', alt: 'Elephant', name: 'Elephant' },
            { src: 'img/udawalawaAnimalNew3.png', alt: 'Elephant', name: 'Elephant' },
            { src: 'img/udawalawaAnimal.png', alt: 'Deer', name: 'Deer' },
            { src: 'img/udawalawaAnimal5.png', alt: 'Peacock', name: ' Peacock' },
            { src: 'img/udawalawaAnimalNew4.png', alt: 'Elephant', name: 'Elephant' },
            { src: 'img/udawalawaAnimal3.png', alt: 'Elephant', name: 'Dwarf Elephant' },

        ],
        yala: [
            { src: 'img/yalaAnimal (10).png', alt: 'Tigers', name: 'Eagle' },
            { src: 'img/YalaAni.png', alt: 'Mongoose', name: 'Leopards' },
            { src: 'img/udawalawaAnimal4.png', alt: 'Indian chameleon', name: 'Painted stork' },
            { src: 'img/Yala-National-Park-1.jpg', alt: 'Crested Hawk Eagle', name: 'Leopards' },
            { src: 'img/yalaAnimal (8).png', alt: 'Crocodile', name: 'Leopard' },
            { src: 'img/yalaAni2.jpg', alt: 'Peacock', name: 'Leopards' }
        ]
    };

    const selectedImages = images[selectedPark];

    const [show, setShow] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);

    const handleClose = () => setShow(false);

    const handleShow = (pkg) => {
        setSelectedPackage(pkg);
        setShow(true);
    };

    const [visible, setVisible] = useState(false)

    const [isReadMoreVisible1, setIsReadMoreVisible1] = useState(false);
    const [isReadMoreVisible2, setIsReadMoreVisible2] = useState(false);
    const [isReadMoreVisible3, setIsReadMoreVisible3] = useState(false);

    const handleReadMoreToggleNormal = () => {
        setIsReadMoreVisible1(!isReadMoreVisible1);
    };
    const handleReadMoreToggleHalf = () => {
        setIsReadMoreVisible2(!isReadMoreVisible2);
    };
    const handleReadMoreToggleFull = () => {
        setIsReadMoreVisible3(!isReadMoreVisible3);
    };



    /*-----------------------------------------*/
    const [isReadMoreVisible4, setIsReadMoreVisible4] = useState(false);
    const [isReadMoreVisible5, setIsReadMoreVisible5] = useState(false);
    const [isReadMoreVisible6, setIsReadMoreVisible6] = useState(false);

    const handleReadMoreToggleNormal4 = () => {
        setIsReadMoreVisible4(!isReadMoreVisible4);
    };
    const handleReadMoreToggleHalf5 = () => {
        setIsReadMoreVisible5(!isReadMoreVisible5);
    };
    const handleReadMoreToggleFull6 = () => {
        setIsReadMoreVisible6(!isReadMoreVisible6);
    };



    const navigate = useNavigate();

    const handleYalaShowB = () => {
        window.location.replace('/packages')
    };


    return (

        <>
            {/*Spinner Start */}
            {/*<div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                <div class="spinner-border text-primary" style={{width: '3rem',  height: '3rem'}} role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>*/}
            {/* Spinner End*/}


            {/*Header Start */}
            <div className="">
                <div className="row g-0 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6 p-0 wow fadeIn " data-wow-delay="0.1s">
                        <div
                            className="header-bg h-100 d-flex flex-column justify-content-center p-5 header_bg-homeMain">
                            <h1 className="display-4 text-light mb-5">Planting Wildlife in your Mind </h1>
                            <p className="text-light font-size: 30px">Welcome to an adventurous journey, we are the
                                master planners of Yala & Udawalawe wildlife safaris here in Sri Lanka!</p>
                            {/*<div className="d-flex align-items-center pt-4 animated slideInDown">
                                <a href="" className="btn btn-primary py-sm-3 px-3 px-sm-5 me-5">Book Now</a>
                                <button type="button" className="btn-play" data-bs-toggle="modal"
                                        data-src="https://www.youtube.com/embed/DWRcNpR6Kdc"
                                        data-bs-target="#videoModal">
                                    <span></span>
                                </button>
                                <h6 className="text-white m-0 ms-4 d-none d-sm-block">Watch Video</h6>
                            </div>*/}
                            <div className="d-flex align-items-center pt-4 animated slideInDown">
                                <a href="/Packages" className="btn btn-primary py-sm-3 px-3 px-sm-5 me-5 blinking-btn">Book
                                    Now</a>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                        <OwlCarousel
                            className="owl-theme header-carousel"
                            items={1}
                            loop
                            autoplay
                            smartSpeed={1000}
                            dots
                        >
                            {/*<div className="owl-carousel-item">
                                <img className="img-fluid" src="img/slide1.jpg" alt="Carousel 1"/>
                            </div>*/}

                            <div className="owl-carousel-item">

                                <img className="img-fluid" src="img/ubawalawa1.jpg" alt="Carousel 2" />
                            </div>
                            <div className="owl-carousel-item">
                                <img className="img-fluid" src="img/play6.jpg" alt="Carousel 3" />

                            </div>
                            <div className="owl-carousel-item">
                                <img className="img-fluid" src="img/bearN.jpg" alt="Carousel 3" />
                            </div>
                            <div className="owl-carousel-item">

                                <img className="img-fluid" src="img/crocadileN.jpg" alt="Carousel 3" />
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
            {/*Header End*/}

            {/* Video Modal Start */}
            <div className="modal modal-video fade" id="videoModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content rounded-0">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">Youtube Video</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {/* 16:9 aspect ratio*/}
                            <div className="ratio ratio-16x9">
                                <iframe className="embed-responsive-item" src="" id="video" allowfullscreen
                                    allowscriptaccess="always"
                                    allow="autoplay"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Video Modal End*/}


            {/* About Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            {/* <p><span className="text-primary me-2">#</span>Vibes of Safari</p> */}
                            <h1 className="display-5 mb-4">
                                Why you should visit <span
                                    className="text-primary">Udawalawe and Yala National</span> Parks!
                            </h1>
                            <p className="mb-4">
                                Udawalawe National Park and Yala National park are the home for many wild animals in Sri
                                Lanka. The visitors can have a close and personal experience with giant elephants,
                                ferocious leopards, and many more wild animals.
                            </p>
                            <p className="mb-3" style={{ fontSize: '17px', fontWeight: 'bold' }}>
                                <i className="far fa-check-circle text-primary me-3"></i>
                                You can witness big herds of elephants in Udawalawe National park
                            </p>
                            <p className="mb-3" style={{ fontSize: '17px', fontWeight: 'bold' }}>
                                <i className="far fa-check-circle text-primary me-3"></i>
                                You can witness mighty leopards in Yala National Park
                            </p>
                            <p className="mb-3" style={{ fontSize: '17px', fontWeight: 'bold' }}>
                                <i className="far fa-check-circle text-primary me-3"></i>
                                Udawalawe, with its fascinating wildlife, history, and culture, offers an array of
                                historical sites and natural wonders
                            </p>
                            <p className="mb-3" style={{ fontSize: '17px', fontWeight: 'bold' }}>
                                <i className="far fa-check-circle text-primary me-3"></i>
                                Yala National Park is widely known for its incredible bio-diversity as it hosts a
                                variety of ecosystems ranging from riverine forests, grasslands, freshwater wetlands, to
                                marine ecosystems.
                            </p>
                            <p className="mb-3" style={{ fontSize: '17px', fontWeight: 'bold' }}>
                                <i className="far fa-check-circle text-primary me-3"></i>
                                The most popular safari destinations in Sri Lanka are Yala and Udawalawe National Parks
                            </p>
                            <a className="btn btn-primary py-3 px-5 mt-3" href="/more">Read More</a>
                        </div>

                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="img-border">
                                <img className="img-fluid" src="img/Safarii_Gome.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About End */}


            {/* Facts Start*/}

            {/*Udawalawa*/}
            <div
                className="container-xxl bg-primary facts my-5 py-5 wow fadeInUp header-bg-Udawalawa udawalawe-section-new"
                data-wow-delay="0.1s" style={{ minHeight: '500px' }}>
                <div className="container py-5">
                    {/* Udawalawe National Park */}
                    <div className="udawalawe-section" style={{ minHeight: '100px' }}>
                        <h2 style={{
                            color: 'white',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '30px'
                        }}>Udawalawe National Park</h2>
                        <div className="row g-4 mt-5">
                            <div className="col-md-6 col-lg-3 text-center wow fadeIn flex justify-content-start"
                                data-wow-delay="0.1s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-paw fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Total Animals</p>
                                <p className="text-white mb-2" data-toggle="counter-up">Udawalawe park includes 94
                                    plants, 21 fishes, 12 amphibians, 33 reptiles, 184 birds (33 of which are
                                    migratory),
                                    and 43 mammals</p>

                            </div>

                            <div className="col-md-6 col-lg-3 text-center wow fadeIn flex justify-content-start"
                                data-wow-delay="0.5s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-certificate fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Area</p>
                                <p className="text-white mb-2" data-toggle="counter-up">119 sq moles</p>

                            </div>
                            <div className="col-md-6 col-lg-3 text-center wow fadeIn flex justify-content-start"
                                data-wow-delay="0.7s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-shield-alt fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Climate</p>
                                <p className="text-white mb-2" data-toggle="counter-up">Dry most of the year</p>

                            </div>
                            <div className="col-md-6 col-lg-3 text-center wow fadeIn flex justify-content-start"
                                data-wow-delay="0.3s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-users fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Nearby Places </p>
                                <p className="text-white mb-2" data-toggle="counter-up">Elephant Orphanage, Sankalpa
                                    Viharaya, Maduwanwela Walawwa
                                </p>

                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <ResponsiveDialogUdawalawa />
                        </div>
                    </div>
                </div>
            </div>


            {/* Yala */}
            <div className="container-xxl bg-primary facts my-5 py-5 wow fadeInUp header-bg-Yala  udawalawe-section-new"
                data-wow-delay="0.1s" style={{ minHeight: '500px' }}>
                <div className="container py-5">

                    {/* Yala National Park */}
                    <div className="yala-section mt-5" style={{ minHeight: '100px' }}>
                        <h2 style={{ color: 'white', textAlign: 'center', fontWeight: 'bold', fontSize: '30px' }}>Yala
                            National Park</h2>
                        <div className="row g-4 mt-5">
                            <div className="col-md-6 col-lg-3 text-center wow fadeIn justify-content-start"
                                data-wow-delay="0.1s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-paw fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Total Animals</p>
                                <p className="text-white mb-2" data-toggle="counter-up">Yala is home to 44 varieties of
                                    mammals and 215 bird species</p>

                            </div>

                            <div className="col-md-6 col-lg-3 text-center wow fadeIn justify-content-start"
                                data-wow-delay="0.5s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-certificate fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Area</p>
                                <p className="text-white mb-2" data-toggle="counter-up">378 sq miles</p>

                            </div>
                            <div className="col-md-6 col-lg-3 text-center wow fadeIn justify-content-start"
                                data-wow-delay="0.7s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-shield-alt fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Climate</p>
                                <p className="text-white mb-2" data-toggle="counter-up">Dry most of the year</p>

                            </div>
                            <div className="col-md-6 col-lg-3 text-center wow fadeIn justify-content-start"
                                data-wow-delay="0.3s"
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                <i className="fa fa-users fa-3x text-primary mb-3"></i>
                                <p className="text-white mb-0" style={{ fontWeight: 'bold' }}>Nearby Places </p>
                                <p className="text-white mb-2" data-toggle="counter-up">Buduruwagala, Okanda Devalaya,
                                    Kebiliththa, Sithulpawwa, Magul Maha Viharaya
                                </p>

                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <ResponsiveDialogYala />
                        </div>
                    </div>
                </div>
            </div>

            {/*   Facts End */}


            {/*Service Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    {/*<div className="row g-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="col-lg-6">
                            <p><span className="text-primary me-2">#</span>Our Services</p>
                            <h1 className="display-5 mb-0">What’s included for <span className="text-primary">Udawalawe and Yala National Park </span> Visitors
                            </h1>
                        </div>
                        <div className="col-lg-6">
                            <div className="bg-primary h-100 d-flex align-items-center py-4 px-4 px-sm-5">
                                <i className="fa fa-3x fa-mobile-alt text-white"></i>
                                <div className="ms-4">
                                    <p className="text-white mb-0">Call for more info</p>
                                    <h2 className="text-white mb-0">+012 345 6789</h2>
                                </div>
                            </div>
                        </div>
                    </div>*/}

                    <div className="container-xxl py-5">
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <h1 className="display-5 mb-4">Why Book With Us?{/*<span
                                        className="text-primary"></span>*/}</h1>
                                    <div className="detail-container">
                                        <div style={{ display: 'flex', justifyContent: 'start' }} >
                                            <h5><i className="far fa-check-circle text-primary me-3" style={{ textAlign: 'start' }}></i></h5>
                                            <h5 className="mb-3 text-center1">
                                                Experienced Safari Game Drivers
                                            </h5>
                                        </div>

                                        <ul className="no-bullets ms-5">
                                            <li>We have a team of very experienced safari game drivers who can give you
                                                the best Safari experience.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="detail-container">
                                        <div style={{ display: 'flex', justifyContent: 'start' }} >
                                            <h5><i className="far fa-check-circle text-primary me-3" style={{ textAlign: 'start' }}></i></h5>
                                            <h5 className="mb-3 text-center1">
                                                Comfortable 4x4 Safari Jeeps
                                            </h5>
                                        </div>

                                        <ul className="no-bullets ms-5">
                                            <li>We provide very comfortable and spacious Safari jeeps for you to have a
                                                safe Safari drive.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="detail-container">
                                        <div style={{ display: 'flex', justifyContent: 'start' }} >
                                            <h5><i className="far fa-check-circle text-primary me-3" style={{ textAlign: 'start' }}></i></h5>
                                            <h5 className="mb-3 text-center1">
                                                Free Hotel Pick-Up and drop off
                                            </h5>
                                        </div>

                                        <ul className="no-bullets ms-5">
                                            <li>We provide free transportation for 10KM around the park.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="detail-container">
                                        <div style={{ display: 'flex', justifyContent: 'start' }} >
                                            <h5><i className="far fa-check-circle text-primary me-3" style={{ textAlign: 'start' }}></i></h5>
                                            <h5 className="mb-3 text-center1">
                                                Prioritize Your Needs
                                            </h5>
                                        </div>

                                        <ul className="no-bullets ms-5">
                                            <li>We prioritize your needs depending on your preference of animals and
                                                preference of experience.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="detail-container">
                                        <div style={{ display: 'flex', justifyContent: 'start' }} >
                                            <h5><i className="far fa-check-circle text-primary me-3" style={{ textAlign: 'start' }}></i></h5>
                                            <h5 className="mb-3 text-center1">
                                                No Hidden Charges
                                            </h5>
                                        </div>

                                        <ul className="no-bullets ms-5">
                                            <li>No hidden charges or taxes will be added.
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div className="img-border">
                                        <img className="img-fluid" src="img/aboutUs.jpg" alt=""></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/*Service End */}


            {/*Animal Start */}

            <div className="container-xxl py-5">

                <h1 className="display-5 mb-4" style={{ textAlign: 'center' }}>Gallery{/*<span
                    className="text-primary"></span>*/}</h1>
                <div className="container">

                    <div className="row g-0 mb-5 align-items-center wow fadeInUp" data-wow-delay="0.9s"

                        style={{ justifyContent: 'center', gap: '10px' }}>
                        <div className="gallery-btn-div col-auto text-center" style={{ padding: '0' }}>
                            <span id="gallery-btn" onClick={() => setSelectedPark('udawalawe')}
                                className="btn-text text-dark"
                                style={{
                                    cursor: 'pointer',
                                    display: 'inline-block',
                                    fontWeight: 'bold',
                                    fontSize: '21px'
                                }}>
                                Udawalawe National Park
                            </span>
                        </div>
                        <div className="gallery-btn-div col-auto text-center" style={{ padding: '0' }}>
                            <span id="gallery-btn" onClick={() => setSelectedPark('yala')}
                                className="btn-text text-dark"
                                style={{
                                    cursor: 'pointer',
                                    display: 'inline-block',
                                    fontWeight: 'bold',

                                    fontSize: '21px'
                                }}>
                                Yala National Park
                            </span>


                        </div>
                    </div>
                    <div id="image-container" className="row g-4">
                        {selectedImages.map((image, index) => (
                            <div key={index} className="col-lg-4 col-md-6 wow fadeInUp">
                                <div className="row g-4">
                                    <div className="col-12">
                                        <div className="animal-item" onClick={() => {
                                            setIsOpen(true);
                                            setPhotoIndex(index);
                                        }}>
                                            <div className="position-relative">
                                                <img className="img-fluid fixed-size-img " src={image.src}
                                                    alt={image.alt} />
                                                <div className="animal-text p-4">
                                                    <p className="text-white small text-uppercase mb-0">Animal</p>
                                                    <h5 className="text-white mb-0">{image.name}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={selectedImages[photoIndex].src}
                        nextSrc={selectedImages[(photoIndex + 1) % selectedImages.length].src}
                        prevSrc={selectedImages[(photoIndex + selectedImages.length - 1) % selectedImages.length].src}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + selectedImages.length - 1) % selectedImages.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % selectedImages.length)}
                    />
                )}
            </div>

            {/*  Animal End*/}


            {/*Visiting Hours Start*/}
            <div className="container-xxl bg-primary visiting-hours my-5 py-5 wow fadeInUp header_bg-visitingHour"
                data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-md-6 wow fadeIn" data-wow-delay="0.3s">
                            <h1 className="display-6 text-white mb-5">Visiting Hours & Available Options </h1>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <span> </span>
                                    {/* <span>9:00AM - 6:00PM</span> */}
                                </li>
                                <li className="list-group-item">
                                    <span>Safari’s are open everyday from </span>
                                    <span>6:00 AM to 4:30 PM</span>
                                </li>
                                <li className="list-group-item">
                                    <span style={{ color: 'yellow' }}>We recommend that early morning and the evening time will be the ideal time to have this experience. </span>

                                </li>

                                {/* <li class="list-group-item">
                                    <span>Saturday</span>
                                    <span>9:00AM - 6:00PM</span>
                                </li>
                                <li class="list-group-item">
                                    <span>Sunday</span>
                                    <span>Closed</span>
                                </li> */}
                            </ul>
                        </div>
                        <div className="col-md-6 text-light wow fadeIn" data-wow-delay="0.5s">

                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Normal Safari</td>
                                        <td>3/4 hr</td>
                                    </tr>
                                    <tr>
                                        <td>Half Day Safari</td>
                                        <td>6hr</td>
                                    </tr>
                                    <tr>
                                        <td>Full Day Safari</td>
                                        <td>12hr</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/*Visiting Hours End */}


            {/*Membership Start*/}
            <div className="container-xxl py-5">
                <div className="container">

                    {/* Udawalawa Packages */}
                    <div className="py-5">
                        <div className="row g-5 mb-5 align-items-end wow fadeInUp" data-wow-delay="0.1s">
                            <div className="col-lg-6">
                                <h1 className="display-5 mb-0"> <span
                                    className="text-primary">Udawalawa</span> Packages <span
                                        className="text-primary"></span></h1>
                            </div>
                            {/*<div className="col-lg-6 text-lg-end">
                                <a className="btn btn-primary py-3 px-5" href="#">Special Pricing</a>
                            </div>*/}
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                {/* Normal Safari Udawalawa */}
                                <div
                                    className="membership-item position-relative d-flex flex-column"
                                    style={{ height: 'auto', backgroundColor: 'white', border: '2px solid black' }}
                                >
                                    {/*<h1 className="display-1">01</h1>*/}
                                    <h4 className="text-dark mb-3">Normal safari (3 hr)</h4>

                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>
                                        This is the ideal safari with a limited time.
                                        {isReadMoreVisible1 && (
                                            <span>
                                                <br />In three hours, you can cover a
                                                good area of the park. You can select the morning or evening time to explore wildlife & capture moments!

                                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleNormal}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {isReadMoreVisible1 ? 'Read Less' : 'Read More'}
                                    </button>
                                    {/* <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>24 $</span>
                                    </p>
                                    <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black', marginRight: '5px' }}></i>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> persons - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>27 $ <br /> <span
                                                style={{ color: 'black' }}>
                                                (Price varies by group size - maximum 6 pax per jeep)
                                            </span></span>
                                    </p> */}
                                    <button
                                        className="btn btn-outline-light px-4 mt-auto text-dark"
                                        style={{ border: '2px solid #4CAF50', backgroundColor: '#4CAF50' }}
                                        onClick={() => handleYalaShowB('Normal Safari Yala')}
                                    >
                                        Book Now
                                    </button>

                                </div>
                            </div>
                            {/*-----------------*/}
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                {/* Half Day Safari */}
                                <div
                                    className="membership-item position-relative d-flex flex-column "
                                    style={{ height: 'auto', backgroundColor: 'white', border: '2px solid black' }}
                                >
                                    {/*<h1 className="display-1">02</h1>*/}
                                    <h4 className="text-dark mb-3">Half Day Safari (6hr)</h4>


                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>
                                        This 6 hours safari will allow you to cover a considerable area of the park.
                                        {isReadMoreVisible2 && (
                                            <span>
                                                <br />You can choose morning or evening time. You can get down from the jeep and enjoy moments. This is an ideal safari for photographers.

                                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleHalf}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {isReadMoreVisible2 ? 'Read Less' : 'Read More'}
                                    </button>
                                    {/* <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>45 $</span>
                                    </p>
                                    <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black', marginRight: '5px' }}></i>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>48 $ <br /> <span
                                                style={{ color: 'black' }}>
                                                (Price varies by group size - maximum 6 pax per jeep)
                                            </span></span>
                                    </p> */}

                                    <button
                                        className="btn btn-outline-light px-4 mt-auto text-dark"
                                        style={{ border: '2px solid #4CAF50', backgroundColor: '#4CAF50' }}
                                        onClick={() => handleYalaShowB('Normal Safari Yala')}
                                    >
                                        Book Now
                                    </button>

                                </div>
                            </div>
                            {/*------------------------------*/}
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                {/* Full Day Safari */}
                                <div
                                    className="membership-item position-relative d-flex flex-column"
                                    style={{ height: 'auto', backgroundColor: 'white', border: '2px solid black' }}

                                >
                                    {/*<h1 className="display-1">03</h1>*/}
                                    <h4 className="text-dark mb-3">Full Day Safari (12hr)</h4>

                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>
                                        You can cover a huge area of the park and this is recommended for photographers, researchers and wildlife enthusiasts. 
                                        {isReadMoreVisible3 && (
                                            <span>
                                                <br />You can go to different biodiversity areas within this time & you can also have the lunch in the park.

                                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleFull}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {isReadMoreVisible3 ? 'Read Less' : 'Read More'}
                                    </button>
                                    {/* <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>93 $</span></p>
                                    <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black', marginRight: '5px' }}></i>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold', }}>97 $ <br /> <span
                                                style={{ color: 'black' }}>
                                                (Price varies by group size - maximum 6 pax per jeep)
                                            </span></span>
                                    </p> */}
                                    <button
                                        className="btn btn-outline-light px-4 mt-auto text-dark"
                                        style={{ border: '2px solid #4CAF50', backgroundColor: '#4CAF50' }}
                                        onClick={() => handleYalaShowB('Normal Safari Yala')}
                                    >
                                        Book Now
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Yala Packages */}
                    <div className="py-5">
                        <div className="row g-5 mb-5 align-items-end wow fadeInUp" data-wow-delay="0.1s">
                            <div className="col-lg-6">
                                <h1 className="display-5 mb-0"> <span
                                    className="text-primary">Yala</span> Packages <span
                                        className="text-primary"></span></h1>
                            </div>
                            <ul>
                                <li>
                                    <p style={{ color: 'black', fontWeight: 'bold', fontSize: '17px' }}>No entry or movement in the park is
                                        allowed from 12noon
                                        to 2p.m</p>
                                </li>
                            </ul>
                            {/*<div className="col-lg-6 text-lg-end">
                                <a className="btn btn-primary py-3 px-5" href="#">Special Pricing</a>
                            </div>*/}
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                {/* Normal Safari Yala */}
                                <div
                                    className="membership-item position-relative d-flex flex-column "

                                    style={{ height: 'auto', backgroundColor: 'white', border: '2px solid black' }}
                                >

                                    {/*<h1 className="display-1">01</h1>*/}
                                    <h4 className="text-dark mb-3">Normal safari (4 hr)</h4>

                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>
                                        This is the perfect safari for those with limited time.
                                        {isReadMoreVisible4 && (
                                            <span>
                                                <br />In four hours, you can explore a significant part of the park. Our guide/driver will explain all things about wild animals and the national park. Choose a morning or evening safari to observe wildlife and capture memorable moments!
                                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleNormal4}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {isReadMoreVisible4 ? 'Read Less' : 'Read More'}
                                    </button>
                                    {/* <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>53 $</span></p>
                                    <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black', marginRight: '5px' }}></i>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>58 $ <br />
                                            <span style={{ color: 'black' }}>
                                                (Price varies by group size - maximum 6 pax per jeep)
                                            </span></span>
                                    </p> */}

                                    <button
                                        className="btn btn-outline-light px-4 mt-auto text-dark"
                                        style={{ border: '2px solid #4CAF50', backgroundColor: '#4CAF50' }}
                                        onClick={() => handleYalaShowB('Normal Safari Yala')}
                                    >
                                        Book Now
                                    </button>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                {/* Half Day Safari */}
                                <div
                                    className="membership-item position-relative d-flex flex-column "

                                    style={{ height: 'auto', backgroundColor: 'white', border: '2px solid black' }}>
                                    {/*<h1 className="display-1">02</h1>*/}
                                    <h4 className="text-dark mb-3">Half Day Safari (6hr)</h4>
                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>
                                        This six-hour safari lets you explore a substantial portion of the park.
                                        {isReadMoreVisible5 && (
                                            <span>
                                                <br /> You can choose between morning or evening safari or tour. Our guide/driver will explain all things about wild animals and the national park. You’ll have opportunities to get down from the jeep and enjoy your surroundings. It's an ideal option for photographers.
                                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleHalf5}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {isReadMoreVisible5 ? 'Read Less' : 'Read More'}
                                    </button>

                                    {/* <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>73 $</span></p>
                                    <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black', marginRight: '5px' }}></i>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>78 $ <br /> <span
                                                style={{ color: 'black' }}>
                                                (Price varies by group size - maximum 6 pax per jeep)
                                            </span></span>
                                    </p> */}

                                    <button
                                        className="btn btn-outline-light px-4 mt-auto text-dark"
                                        style={{ border: '2px solid #4CAF50', backgroundColor: '#4CAF50' }}
                                        onClick={() => handleYalaShowB('Normal Safari Yala')}
                                    >
                                        Book Now
                                    </button>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                {/* Full Day Safari */}
                                <div
                                    className="membership-item position-relative d-flex flex-column "
                                    style={{ height: 'auto', backgroundColor: 'white', border: '2px solid black' }}
                                >
                                    {/*<h1 className="display-1">03</h1>*/}
                                    <h4 className="text-dark mb-3">Full Day Safari (12hr)</h4>

                                    <p className="text-dark mb-3" style={{ color: 'yellow' }}>
                                        This twelve-hour safari allows you to explore a vast area of the park, making it perfect for photographers, researchers, and wildlife enthusiasts.
                                        {isReadMoreVisible6 && (
                                            <span>
                                                <br /> Our guide/driver will explain all things about wild animals and the national park. You'll have the chance to visit various biodiversity zones and enjoy lunch within the park.

                                            </span>
                                        )}
                                    </p>
                                    <button
                                        className="btn btn-link text-dark p-0 mb-3"
                                        onClick={handleReadMoreToggleFull6}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        {isReadMoreVisible6 ? 'Read Less' : 'Read More'}
                                    </button>
                                    {/* <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>123 $</span></p>
                                    <p className="text-primary mb-4" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i>
                                        <i className="fas fa-user" style={{ color: 'black' }}></i> person - <span
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}>128 $ <br /> <span
                                                style={{ color: 'black' }}>
                                                (Price varies by group size - maximum 6 pax per jeep)
                                            </span></span>
                                    </p> */}
                                    <button
                                        className="btn btn-outline-light px-4 mt-auto text-dark"
                                        style={{ border: '2px solid #4CAF50', backgroundColor: '#4CAF50' }}
                                        onClick={() => handleYalaShowB('Normal Safari Yala')}
                                    >
                                        Book Now
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modals */}

                    {/*Normal Safari Udawalawa
                    <Modal show={showYalaNormal} onHide={handleUdawalawaClose} className="custom-modal"
                           style={{borderRadius: 50}}>
                        <Modal.Header closeButton className="border-0">
                            <Modal.Title className="text-primary">{selectedPackage}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedPackage === 'Normal Safari Udawalawa' && (
                                <>
                                    <h4>Normal Safari (3 hr)</h4>
                                    <ul style={{
                                        listStyleType: 'none',
                                        paddingLeft: 0,
                                        fontFamily: 'Arial, sans-serif'
                                    }}>
                                         List items for Normal Safari Udawalawa
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                        <span style={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: '#4CAF50',
                                            marginRight: '10px'
                                        }}></span>
                                            <span>Free hotel pickup and drop (Udawalawa area)</span>
                                        </li>
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                                <span style={{
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '50%',
                                                    backgroundColor: '#4CAF50',
                                                    marginRight: '10px'
                                                }}></span>
                                            <span>Feel free to contact VS directly for questions or bookings; please avoid contacting us through others.</span>
                                        </li>
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                        <span style={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: '#4CAF50',
                                            marginRight: '10px'
                                        }}></span>
                                            <span>You can't feed animals in the park. It's not allowed.</span>
                                        </li>
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                        <span style={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: '#ff0000',
                                            marginRight: '10px'
                                        }}></span>
                                            <span>Not wheelchair accessible</span>
                                        </li>
                                        {showHide && (
                                            <>
                                                <div style={{
                                                    borderLeft: '5px solid #4CAF50',
                                                    backgroundColor: '#f9f9f9',
                                                    borderRadius: '5px',
                                                    padding: '15px',
                                                    marginBottom: '15px'
                                                }}>
                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.6,
                                                        color: '#333'
                                                    }}>
                                                        You can see a sizable portion of the national park in these
                                                        three hours. It will allow you to witness a variety of species.
                                                        For photographers, a three-hour safari might not be sufficient.
                                                    </p>

                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.6,
                                                        color: '#333'
                                                    }}>
                                                        If you are lucky enough to observe wildlife early in the tour,
                                                        you will have the opportunity to get out of the jeep. If not,
                                                        the driver will search for wild creatures by traveling to other
                                                        locations. Select this safari if you're short on time; if not,
                                                        opt for a half-day safari and save a little more money.
                                                    </p>
                                                </div>

                                                <h4>What’s Included </h4>

                                                <ul style={{
                                                    listStyleType: 'none',
                                                    paddingLeft: 0,
                                                    fontFamily: 'Arial, sans-serif'
                                                }}>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px',
                                                        borderBottom: '1px solid #ddd'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#007BFF',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        4x4 Comfortable Safari Jeep
                                                    </li>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px',
                                                        borderBottom: '1px solid #ddd'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#28A745',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Experienced Driver & Guide
                                                    </li>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#17A2B8',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Water bottles
                                                    </li>

                                                    <h4>What’s Not Included </h4>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#ff0000',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Park entrance tickets
                                                    </li>

                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        color: 'red',
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#ff0000',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        <span></span>
                                                        <span style={{fontWeight: 'bold'}}>Note</span>: Price varies by
                                                        group size
                                                        Maximum 6 pax per jeep
                                                    </li>
                                                </ul>
                                            </>
                                        )}
                                        <li style={{width: '100%', textAlign: 'center', marginTop: '10px'}}>
                                            <button
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#333',
                                                    backgroundColor: 'transparent',
                                                    border: '2px solid #58C039FF',
                                                    borderRadius: '5px',
                                                    padding: '10px 20px',
                                                    fontSize: '16px',
                                                    transition: 'background-color 0.3s, color 0.3s',
                                                    display: 'inline-block'
                                                }}
                                                onClick={handleToggleHideShow}
                                                onMouseEnter={(e) => {
                                                    e.target.style.backgroundColor = '#58c039';
                                                    e.target.style.color = '#fff';
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.style.backgroundColor = 'transparent';
                                                    e.target.style.color = '#333';
                                                }}
                                            >
                                                <strong>Description</strong>
                                            </button>
                                        </li>
                                    </ul>

                                </>

                            )}
                             Additional conditionals for other packages if needed
                        </Modal.Body>
                        <Modal.Footer className="border-0">
                            <Button variant="outline-secondary" onClick={handleUdawalawaClose}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    Normal Safari Yala'
                    <Modal show={showUdawalaNormal} onHide={handleYalaClose} className="custom-modal"
                           style={{borderRadius: 50}}>
                        <Modal.Header closeButton className="border-0">
                            <Modal.Title className="text-primary">{selectedPackage}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedPackage === 'Normal Safari Yala' && (
                                <>
                                    <h4>Normal Safari (4 hr)</h4>
                                    <ul style={{
                                        listStyleType: 'none',
                                        paddingLeft: 0,
                                        fontFamily: 'Arial, sans-serif'
                                    }}>
                                         List items for Normal Safari Udawalawa
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                        <span style={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: '#4CAF50',
                                            marginRight: '10px'
                                        }}></span>
                                            <span>Water bottel</span>
                                        </li>
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                                <span style={{
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '50%',
                                                    backgroundColor: '#4CAF50',
                                                    marginRight: '10px'
                                                }}></span>
                                            <span>Free hotel pickup & drop(Yala area)</span>
                                        </li>

                                        {showHide && (
                                            <>
                                                <div style={{
                                                    borderLeft: '5px solid #4CAF50',
                                                    backgroundColor: '#f9f9f9',
                                                    borderRadius: '5px',
                                                    padding: '15px',
                                                    marginBottom: '15px'
                                                }}>
                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.6,
                                                        color: '#333'
                                                    }}>
                                                        You can see a sizable portion of the national park in these
                                                        three hours. It will allow you to witness a variety of species.
                                                        For photographers, a three-hour safari might not be sufficient.
                                                    </p>

                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.6,
                                                        color: '#333'
                                                    }}>
                                                        If you are lucky enough to observe wildlife early in the tour,
                                                        you will have the opportunity to get out of the jeep. If not,
                                                        the driver will search for wild creatures by traveling to other
                                                        locations. Select this safari if you're short on time; if not,
                                                        opt for a half-day safari and save a little more money.
                                                    </p>
                                                </div>

                                                <h4>What’s Included </h4>

                                                <ul style={{
                                                    listStyleType: 'none',
                                                    paddingLeft: 0,
                                                    fontFamily: 'Arial, sans-serif'
                                                }}>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px',
                                                        borderBottom: '1px solid #ddd'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#007BFF',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        4x4 Comfortable Safari Jeep
                                                    </li>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px',
                                                        borderBottom: '1px solid #ddd'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#28A745',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Experienced Driver & Guide
                                                    </li>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#17A2B8',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Water bottles
                                                    </li>

                                                    <h4>What’s Not Included </h4>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#ff0000',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Park entrance tickets
                                                    </li>

                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        color: 'red',
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#ff0000',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        <span></span>
                                                        <span style={{fontWeight: 'bold'}}>Note</span>: Price varies by
                                                        group size
                                                        Maximum 6 pax per jeep
                                                    </li>
                                                </ul>
                                            </>
                                        )}
                                        <li style={{width: '100%', textAlign: 'center', marginTop: '10px'}}>
                                            <button
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#333',
                                                    backgroundColor: 'transparent',
                                                    border: '2px solid #58C039FF',
                                                    borderRadius: '5px',
                                                    padding: '10px 20px',
                                                    fontSize: '16px',
                                                    transition: 'background-color 0.3s, color 0.3s',
                                                    display: 'inline-block'
                                                }}
                                                onClick={handleToggleHideShow}
                                                onMouseEnter={(e) => {
                                                    e.target.style.backgroundColor = '#58c039';
                                                    e.target.style.color = '#fff';
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.style.backgroundColor = 'transparent';
                                                    e.target.style.color = '#333';
                                                }}
                                            >
                                                <strong>Description</strong>
                                            </button>
                                        </li>
                                    </ul>
                                </>
                            )}
                             Additional conditionals for other packages if needed
                        </Modal.Body>
                        <Modal.Footer className="border-0">
                            <Button variant="outline-secondary" onClick={handleYalaClose}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    Half Day Safari Yala
                    <Modal show={showYalaHalf} onHide={handleYalaHalf} className="custom-modal"
                           style={{borderRadius: 50}}>
                        <Modal.Header closeButton className="border-0">
                            <Modal.Title className="text-primary">{selectedPackage}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedPackage === 'Half Day Safari Yala' && (
                                <>
                                    <h4>Normal Safari (4 hr)</h4>
                                    <ul style={{
                                        listStyleType: 'none',
                                        paddingLeft: 0,
                                        fontFamily: 'Arial, sans-serif'
                                    }}>
                                         List items for Normal Safari Udawalawa
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                        <span style={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: '#4CAF50',
                                            marginRight: '10px'
                                        }}></span>
                                            <span>Water bottel</span>
                                        </li>

                                        {showHide && (
                                            <>
                                                <div style={{
                                                    borderLeft: '5px solid #4CAF50',
                                                    backgroundColor: '#f9f9f9',
                                                    borderRadius: '5px',
                                                    padding: '15px',
                                                    marginBottom: '15px'
                                                }}>
                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.6,
                                                        color: '#333'
                                                    }}>
                                                        You can observe a wide variety of wild creatures and cover a
                                                        sizable portion of the park in a half-day safari ride. It takes
                                                        five to six hours for this trip.
                                                    </p>

                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.6,
                                                        color: '#333'
                                                    }}>
                                                        This makes it better for taking pictures. In certain areas, you
                                                        can exit the jeep.
                                                    </p>
                                                </div>

                                                <h4>What’s Included </h4>

                                                <ul style={{
                                                    listStyleType: 'none',
                                                    paddingLeft: 0,
                                                    fontFamily: 'Arial, sans-serif'
                                                }}>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px',
                                                        borderBottom: '1px solid #ddd'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#007BFF',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        4x4 Comfortable Safari Jeep
                                                    </li>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px',
                                                        borderBottom: '1px solid #ddd'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#28A745',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Experienced Driver & Guide
                                                    </li>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#17A2B8',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Water bottles
                                                    </li>

                                                    <h4>What’s Not Included </h4>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#ff0000',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Park entrance tickets
                                                    </li>

                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        color: 'red',
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#ff0000',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        <span></span>
                                                        <span style={{fontWeight: 'bold'}}>Note</span>: Price varies by
                                                        group size
                                                        Maximum 6 pax per jeep
                                                    </li>
                                                </ul>
                                            </>
                                        )}
                                        <li style={{width: '100%', textAlign: 'center', marginTop: '10px'}}>
                                            <button
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#333',
                                                    backgroundColor: 'transparent',
                                                    border: '2px solid #58C039FF',
                                                    borderRadius: '5px',
                                                    padding: '10px 20px',
                                                    fontSize: '16px',
                                                    transition: 'background-color 0.3s, color 0.3s',
                                                    display: 'inline-block'
                                                }}
                                                onClick={handleToggleHideShow}
                                                onMouseEnter={(e) => {
                                                    e.target.style.backgroundColor = '#58c039';
                                                    e.target.style.color = '#fff';
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.style.backgroundColor = 'transparent';
                                                    e.target.style.color = '#333';
                                                }}
                                            >
                                                <strong>Description</strong>
                                            </button>
                                        </li>
                                    </ul>
                                </>
                            )}
                             Additional conditionals for other packages if needed
                        </Modal.Body>
                        <Modal.Footer className="border-0">
                            <Button variant="outline-secondary" onClick={handleYalaHalf}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    Half Day Safari Udawalawa
                    <Modal show={showUdawalaweHalf} onHide={handleUdawalaweHalf} className="custom-modal"
                           style={{borderRadius: 50}}>
                        <Modal.Header closeButton className="border-0">
                            <Modal.Title className="text-primary">{selectedPackage}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedPackage === 'Half Day Safari Udawalawa' && (
                                <>
                                    <h4>Normal Safari (4 hr)</h4>
                                    <ul style={{
                                        listStyleType: 'none',
                                        paddingLeft: 0,
                                        fontFamily: 'Arial, sans-serif'
                                    }}>
                                         List items for Normal Safari Udawalawa
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                        <span style={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: '#4CAF50',
                                            marginRight: '10px'
                                        }}></span>
                                            <span>Breakfast</span>
                                        </li>
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                                <span style={{
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '50%',
                                                    backgroundColor: '#4CAF50',
                                                    marginRight: '10px'
                                                }}></span>
                                            <span>Water</span>
                                        </li>
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                        <span style={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: '#4CAF50',
                                            marginRight: '10px'
                                        }}></span>
                                            <span>You can't feed animals in the park. It's not allowed.</span>
                                        </li>
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                        <span style={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: '#ff0000',
                                            marginRight: '10px'
                                        }}></span>
                                            <span>Not wheelchair accessible</span>
                                        </li>
                                        {showHide && (
                                            <>
                                                <div style={{
                                                    borderLeft: '5px solid #4CAF50',
                                                    backgroundColor: '#f9f9f9',
                                                    borderRadius: '5px',
                                                    padding: '15px',
                                                    marginBottom: '15px'
                                                }}>
                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.6,
                                                        color: '#333'
                                                    }}>
                                                        For those who wish to learn more about the park and its species,
                                                        including researchers, photographers, adventurers, and wildlife
                                                        aficionados, this is the greatest safari.
                                                    </p>

                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.6,
                                                        color: '#333'
                                                    }}>
                                                        You will be able to explore many biodiversity zones and a
                                                        sizable portion of the park. Lunch is available in the park.
                                                        This Safari is highly recommended, particularly for
                                                        photographers.
                                                    </p>
                                                </div>

                                                <h4>What’s Included </h4>

                                                <ul style={{
                                                    listStyleType: 'none',
                                                    paddingLeft: 0,
                                                    fontFamily: 'Arial, sans-serif'
                                                }}>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px',
                                                        borderBottom: '1px solid #ddd'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#007BFF',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        4x4 Comfortable Safari Jeep
                                                    </li>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px',
                                                        borderBottom: '1px solid #ddd'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#28A745',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Experienced Driver & Guide
                                                    </li>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#17A2B8',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Water bottles
                                                    </li>

                                                    <h4>What’s Not Included </h4>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#ff0000',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Park entrance tickets
                                                    </li>

                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        color: 'red',
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#ff0000',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        <span></span>
                                                        <span style={{fontWeight: 'bold'}}>Note</span>: Price varies by
                                                        group size
                                                        Maximum 6 pax per jeep
                                                    </li>
                                                </ul>
                                            </>
                                        )}
                                        <li style={{width: '100%', textAlign: 'center', marginTop: '10px'}}>
                                            <button
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#333',
                                                    backgroundColor: 'transparent',
                                                    border: '2px solid #58C039FF',
                                                    borderRadius: '5px',
                                                    padding: '10px 20px',
                                                    fontSize: '16px',
                                                    transition: 'background-color 0.3s, color 0.3s',
                                                    display: 'inline-block'
                                                }}
                                                onClick={handleToggleHideShow}
                                                onMouseEnter={(e) => {
                                                    e.target.style.backgroundColor = '#58c039';
                                                    e.target.style.color = '#fff';
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.style.backgroundColor = 'transparent';
                                                    e.target.style.color = '#333';
                                                }}
                                            >
                                                <strong>Description</strong>
                                            </button>
                                        </li>
                                    </ul>
                                </>
                            )}
                             Additional conditionals for other packages if needed
                        </Modal.Body>
                        <Modal.Footer className="border-0">
                            <Button variant="outline-secondary" onClick={handleUdawalaweHalf}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    Full Day Safari Yala
                    <Modal show={showYalaFull} onHide={handleYalaFull} className="custom-modal"
                           style={{borderRadius: 50}}>
                        <Modal.Header closeButton className="border-0">
                            <Modal.Title className="text-primary">{selectedPackage}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedPackage === 'Full Day Safari Yala' && (
                                <>
                                    <h4>Normal Safari (4 hr)</h4>
                                    <ul style={{
                                        listStyleType: 'none',
                                        paddingLeft: 0,
                                        fontFamily: 'Arial, sans-serif'
                                    }}>
                                         List items for Normal Safari Udawalawa
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                        <span style={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: '#4CAF50',
                                            marginRight: '10px'
                                        }}></span>
                                            <span>Lunch</span>
                                        </li>
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                                <span style={{
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '50%',
                                                    backgroundColor: '#4CAF50',
                                                    marginRight: '10px'
                                                }}></span>
                                            <span>Water bottles & soft Drinks</span>
                                        </li>

                                        {showHide && (
                                            <>
                                                <div style={{
                                                    borderLeft: '5px solid #4CAF50',
                                                    backgroundColor: '#f9f9f9',
                                                    borderRadius: '5px',
                                                    padding: '15px',
                                                    marginBottom: '15px'
                                                }}>
                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.6,
                                                        color: '#333'
                                                    }}>
                                                        For those who wish to learn more about the park and its species,
                                                        including researchers, photographers, adventurers, and wildlife
                                                        aficionados, this is the greatest safari.
                                                    </p>

                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.6,
                                                        color: '#333'
                                                    }}>
                                                        You will be able to explore many biodiversity zones and a
                                                        sizable portion of the park. Lunch is available in the park.
                                                        This Safari is highly recommended, particularly for
                                                        photographers.
                                                    </p>
                                                </div>

                                                <h4>What’s Included </h4>

                                                <ul style={{
                                                    listStyleType: 'none',
                                                    paddingLeft: 0,
                                                    fontFamily: 'Arial, sans-serif'
                                                }}>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px',
                                                        borderBottom: '1px solid #ddd'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#007BFF',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        4x4 Comfortable Safari Jeep
                                                    </li>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px',
                                                        borderBottom: '1px solid #ddd'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#28A745',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Experienced Driver & Guide
                                                    </li>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#17A2B8',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Water bottles
                                                    </li>

                                                    <h4>What’s Not Included </h4>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#ff0000',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Park entrance tickets
                                                    </li>

                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        color: 'red',
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#ff0000',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        <span></span>
                                                        <span style={{fontWeight: 'bold'}}>Note</span>: Price varies by
                                                        group size
                                                        Maximum 6 pax per jeep
                                                    </li>
                                                </ul>
                                            </>
                                        )}
                                        <li style={{width: '100%', textAlign: 'center', marginTop: '10px'}}>
                                            <button
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#333',
                                                    backgroundColor: 'transparent',
                                                    border: '2px solid #58C039FF',
                                                    borderRadius: '5px',
                                                    padding: '10px 20px',
                                                    fontSize: '16px',
                                                    transition: 'background-color 0.3s, color 0.3s',
                                                    display: 'inline-block'
                                                }}
                                                onClick={handleToggleHideShow}
                                                onMouseEnter={(e) => {
                                                    e.target.style.backgroundColor = '#58c039';
                                                    e.target.style.color = '#fff';
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.style.backgroundColor = 'transparent';
                                                    e.target.style.color = '#333';
                                                }}
                                            >
                                                <strong>Description</strong>
                                            </button>
                                        </li>
                                    </ul>
                                </>
                            )}
                             Additional conditionals for other packages if needed
                        </Modal.Body>
                        <Modal.Footer className="border-0">
                            <Button variant="outline-secondary" onClick={handleYalaFull}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    Full Day Safari Udawalawa
                    <Modal show={showUdawalaweFull} onHide={handleUsawalweFull} className="custom-modal"
                           style={{borderRadius: 50}}>
                        <Modal.Header closeButton className="border-0">
                            <Modal.Title className="text-primary">{selectedPackage}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedPackage === 'Full Day Safari Udawalawa' && (
                                <>
                                    <h4>Normal Safari (4 hr)</h4>
                                    <ul style={{
                                        listStyleType: 'none',
                                        paddingLeft: 0,
                                        fontFamily: 'Arial, sans-serif'
                                    }}>
                                         List items for Normal Safari Udawalawa

                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                        <span style={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: '#4CAF50',
                                            marginRight: '10px'
                                        }}></span>
                                            <span>Breakfast</span>
                                        </li>
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                        <span style={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: '#ff0000',
                                            marginRight: '10px'
                                        }}></span>
                                            <span>Lunch</span>
                                        </li>
                                        <li style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                                        <span style={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: '#4CAF50',
                                            marginRight: '10px'
                                        }}></span>
                                            <span>Water bottles & soft Drinks</span>
                                        </li>
                                        {showHide && (
                                            <>
                                                <div style={{
                                                    borderLeft: '5px solid #4CAF50',
                                                    backgroundColor: '#f9f9f9',
                                                    borderRadius: '5px',
                                                    padding: '15px',
                                                    marginBottom: '15px'
                                                }}>
                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.6,
                                                        color: '#333'
                                                    }}>
                                                        You can see a sizable portion of the national park in these
                                                        three hours. It will allow you to witness a variety of species.
                                                        For photographers, a three-hour safari might not be sufficient.
                                                    </p>

                                                    <p style={{
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.6,
                                                        color: '#333'
                                                    }}>
                                                        If you are lucky enough to observe wildlife early in the tour,
                                                        you will have the opportunity to get out of the jeep. If not,
                                                        the driver will search for wild creatures by traveling to other
                                                        locations. Select this safari if you're short on time; if not,
                                                        opt for a half-day safari and save a little more money.
                                                    </p>
                                                </div>

                                                <h4>What’s Included </h4>

                                                <ul style={{
                                                    listStyleType: 'none',
                                                    paddingLeft: 0,
                                                    fontFamily: 'Arial, sans-serif'
                                                }}>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px',
                                                        borderBottom: '1px solid #ddd'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#007BFF',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        4x4 Comfortable Safari Jeep
                                                    </li>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px',
                                                        borderBottom: '1px solid #ddd'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#28A745',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Experienced Driver & Guide
                                                    </li>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#17A2B8',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Water bottles
                                                    </li>

                                                    <h4>What’s Not Included </h4>
                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#ff0000',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        Park entrance tickets
                                                    </li>

                                                    <li style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px'
                                                    }}>
                                                    <span style={{
                                                        color: 'red',
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#ff0000',
                                                        borderRadius: '50%',
                                                        marginRight: '10px'
                                                    }}></span>
                                                        <span></span>
                                                        <span style={{fontWeight: 'bold'}}>Note</span>: Price varies by
                                                        group size
                                                        Maximum 6 pax per jeep
                                                    </li>
                                                </ul>
                                            </>
                                        )}
                                        <li style={{width: '100%', textAlign: 'center', marginTop: '10px'}}>
                                            <button
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#333',
                                                    backgroundColor: 'transparent',
                                                    border: '2px solid #58C039FF',
                                                    borderRadius: '5px',
                                                    padding: '10px 20px',
                                                    fontSize: '16px',
                                                    transition: 'background-color 0.3s, color 0.3s',
                                                    display: 'inline-block'
                                                }}
                                                onClick={handleToggleHideShow}
                                                onMouseEnter={(e) => {
                                                    e.target.style.backgroundColor = '#58c039';
                                                    e.target.style.color = '#fff';
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.target.style.backgroundColor = 'transparent';
                                                    e.target.style.color = '#333';
                                                }}
                                            >
                                                <strong>Description</strong>
                                            </button>
                                        </li>
                                    </ul>
                                </>
                            )}
                             Additional conditionals for other packages if needed
                        </Modal.Body>
                        <Modal.Footer className="border-0">
                            <Button variant="outline-secondary" onClick={handleUsawalweFull}>Close</Button>
                        </Modal.Footer>
                    </Modal>*/}

                </div>
            </div>
            {/* Membership End */}


            {/*Testimonial Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <h1 className="display-5 text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">Clients are
                        Happy!</h1>
                    <OwlCarousel
                        className="owl-carousel testimonial-carousel wow fadeInUp"
                        data-wow-delay="0.1s"
                        items={1}
                        loop
                        autoplay
                        dots
                    >
                        <div className="testimonial-item text-center">
                            <img className="img-fluid rounded-circle border border-2 p-2 mx-auto mb-4"
                                src="img/testimonial-1.png" style={{ width: '100px', height: '100px' }}
                                alt="Testimonial 1" />
                            <div className="testimonial-text rounded text-center p-4">
                                <p>Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed.
                                    Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at
                                    rebum justo sea clita.</p>
                                <h5 className="mb-1">Patient Name</h5>
                                <span className="fst-italic">Profession</span>
                            </div>
                        </div>
                        <div className="testimonial-item text-center">
                            <img className="img-fluid rounded-circle border border-2 p-2 mx-auto mb-4"
                                src="img/testimonial-2.png" style={{ width: '100px', height: '100px' }}
                                alt="Testimonial 2" />
                            <div className="testimonial-text rounded text-center p-4">
                                <p>Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed.
                                    Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at
                                    rebum justo sea clita.</p>
                                <h5 className="mb-1">Patient Name</h5>
                                <span className="fst-italic">Profession</span>
                            </div>
                        </div>
                        <div className="testimonial-item text-center">
                            <img className="img-fluid rounded-circle border border-2 p-2 mx-auto mb-4"
                                src="img/testimonial-3.png" style={{ width: '100px', height: '100px' }}
                                alt="Testimonial 3" />
                            <div className="testimonial-text rounded text-center p-4">
                                <p>Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed.
                                    Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at
                                    rebum justo sea clita.</p>
                                <h5 className="mb-1">Patient Name</h5>
                                <span className="fst-italic">Profession</span>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
            {/*Testimonial End */}
        </>
    )
}