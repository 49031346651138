import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import { Container, Row, Col } from 'react-bootstrap';

import {noop} from "bootstrap/js/src/util";
import {Grid, Typography} from "@mui/material";

export default function ResponsiveDialogYala() {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" style={{color: 'white', borderColor: '#2EB872', backgroundColor:'#2EB872'}} onClick={handleClickOpen}>
                Things to do in Yala
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" sx={{borderBottom: '2px solid black'}}>
                    <Typography variant='h5' style={{display:'flex',justifyContent:'center',textAlign:'center'}} > Things to do in Yala & Places to stay near!</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Container>
                            <Row className="my-4  ">
                                <Col xs={12}>
                                    <Typography variant='h6' sx={{ color: '#2EB872',display:'flex',justifyContent:'center' }}> Some nice things to do around Yala!</Typography>
                                </Col>
                            </Row>
                            <Row className="my-4 row-sm">
                                <Col xs={12} sm={6} md={6}>
                                    <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                         src='img/kiriwehera.png' alt='Kiriwehera' />
                                    <figcaption className="figure-caption text-center" style={{fontSize:'12px'}}>Kataragama-Kiri Vehera
                                    </figcaption>
                                </Col>
                                <Col xs={12} sm={6} md={6}>
                                    <Typography variant='body1' sx={{ color: '#000000', fontWeight: 'bold' }}>Kataragama</Typography>

                                    <Typography variant='body2'>Kataragama in Sri Lanka is a well-known pilgrimage town in Sri Lanka, where many people from different religions from both Sri Lanka and South India visit. This place is commonly sacred to Hindus, Buddhists and some indigenous Vedda people residing in Sri Lanka</Typography>
                                </Col>
                            </Row>

                            <Row className="myGridContainer my-4" >
                                <Col xs={12} sm={6} md={6}>
                                    <Typography variant='body2'>This small town is famous for Kataragama temple. Kataragama temple in Sri Lanka has a shrine of Skanda Kumara, also commonly known as Kataragama Deviyo. Another major attraction of Kataragama is the Buddhist stupa Kiri Vehera, which was built around 6th BC. This is believed to be built by a regional king Mahasena. </Typography>
                                </Col>
                                <Col xs={12} sm={6} md={6}>
                                    <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                         src='img/katharagamaDewalaya.jpg' alt='Katharagama Dewalaya' />
                                    <figcaption className="figure-caption text-center" style={{fontSize:'12px'}}>Ruhuṇu Kataragama Devalaya
                                    </figcaption>
                                </Col>
                            </Row>

                            <Row className="my-4 row-sm">
                                <Col xs={12} sm={6} md={6}>
                                    <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                         src='img/Sithulpawwa-Rajamaha-Viharaya-V.jpg' alt='Kiriwehera' />

                                </Col>
                                <Col xs={12} sm={6} md={6}>
                                    <Typography variant='body1' sx={{ color: '#000000', fontWeight: 'bold' }}>Sithulpawwa Rajamaha Viharaya</Typography>

                                    <Typography variant='body2'>
                                        Sithulpawwa Rajamaha Viharaya is an ancient Buddhist monastery located in Hambantota District, South Eastern Sri Lanka.<br/>
                                        Sithulpawwa Rajamaha Viharaya contains large number of stupas, cave temples, Buddha statues a stupa house and image houses spread among a large land area.
                                    </Typography>
                                </Col>
                            </Row>


                            <Row className="my-4 row-sm">
                                <Col xs={12}>
                                    <Typography variant='h6'sx={{ color: '#2EB872',display:'flex',justifyContent:'center' }}>Some iconic places for you to stay near the Yala !</Typography>
                                </Col>
                            </Row>

                            <Row className="my-4 row-sm">
                                <Col xs={12} sm={6} md={6}>
                                    <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                         src='img/yalaHotel1.jpg' alt='Yala Hotel 1' />
                                </Col>
                                <Col xs={12} sm={6} md={6}>
                                    <Typography variant='body1' sx={{ color: '#000000', fontWeight: 'bold' }}>Mahoora - Yala by Eco Team</Typography>
                                    <Typography variant='body2'>
                                        Mahoora - Yala by Eco Team- Level 1 Safe & Secure provides tented accommodations in a wilderness setting at the border of Yala National Park, the 2nd largest national park in Sri Lanka.
                                    </Typography>
                                </Col>
                            </Row>

                            <Row className=" myGridContainer  my-4">
                                <Col xs={12} sm={6} md={6}>
                                    <Typography variant='body1' sx={{ color: '#000000', fontWeight: 'bold' }}>Yala Leopard Lodge</Typography>
                                    <Typography variant='body2'>
                                        Yala Leopard Lodge in Yala provides accommodation, an outdoor swimming pool, a garden and a terrace.
                                        Every unit features a private bathroom and bidet, air conditioning, a flat-screen TV and a fridge. For added convenience, the property can provide towels and bed linen for an extra charge.
                                    </Typography>
                                </Col>
                                <Col xs={12} sm={6} md={6}>
                                    <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                         src='img/yalaNearHotel4.jpg' alt='Yala Near Hotel 4' />
                                </Col>
                            </Row>

                            <Row className="my-4 row-sm">
                                <Col xs={12} sm={6} md={6}>
                                    <img width='100%' style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                         src='img/yalaHotel3.jpg' alt='Yala Hotel 3' />
                                </Col>
                                <Col xs={12} sm={6} md={6}>
                                    <Typography variant='body1' sx={{ color: '#000000', fontWeight: 'bold' }}>Hotel Elephant Reach</Typography>
                                    <Typography variant='body2'>
                                        This nature resort is situated near the Yala National Park and is spread across 4 acres of land. Other nearby attractions include the historic towns of Kataragama, Kirinda, and Sithulpahuwa.
                                    </Typography>
                                </Col>
                            </Row>
                        </Container>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}